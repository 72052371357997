// @flow
import React from 'react';
import { Trans } from 'react-i18next';
import { translate } from 'react-i18next';

type Props = {
  t: string => string,
};

const NewContentAvailable = ({ t }: Props) => (
  <React.Fragment>
    <Trans i18nKey="global.refresh-content-title">
      A new version of Doni will be available after existing Doni tabs are
      closed!
      <br />
      If this message persists you will need to clear your browser cache, click
      <a
        href="https://support.google.com/accounts/answer/32050?hl=en"
        target="_blank"
        rel="noopener noreferrer"
        className="button--inline"
      >
        here
      </a>
      for more info
    </Trans>
  </React.Fragment>
);

export default translate()(NewContentAvailable);
