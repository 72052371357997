// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TimeSince from '../../../../components/TimeSince';
import * as notificationsActions from '../../actions';
import type { Notification } from '../../types';

import './styles.css';

type Props = {
  notification: Notification,
  removeNotifications: (id: number) => void,
  notificationClick: (notification: {
    id: number,
    notificationType: string,
  }) => void,
  history: string => void,
  handleLinkClick: () => void,
};

const getNotificationIconCls = type =>
  type ? ` notifications-nav-item__image--${type}` : '';

const isSelectedCls = notification =>
  !notification.archive ? ' notifications-nav-item--selected' : '';

class NotificationsListItem extends Component<Props> {
  handleRemove = ev => {
    ev.stopPropagation();
    this.props.removeNotifications(this.props.notification.id);
  };

  handleGoTo = () => {
    this.props.notificationClick(this.props.notification);
    this.props.handleLinkClick();
  };

  render() {
    const { notification } = this.props;
    return (
      <div
        className={`notifications-nav-item ${isSelectedCls(notification)}`}
        onClick={this.handleGoTo}
        ta-id="header_notifications-go-to"
      >
        <div className="notifications-nav-item__image-wrapper">
          <div
            className={`notifications-nav-item__image${getNotificationIconCls(
              notification.notificationType
            )}`}
          />
        </div>
        <div className="notifications-nav-item__details details">
          <div className="details__title">{notification.payload.body}</div>
          <div className="details__time">
            <TimeSince date={notification.createdDate} />
          </div>
        </div>
        <div className="notifications-nav-item__actions">
          <button
            className="notifications-nav-item__actions--remove"
            onClick={this.handleRemove}
            ta-id="header_notifications-delete"
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  removeNotifications: id =>
    dispatch(notificationsActions.deleteNotification(id)),
  notificationClick: notification =>
    dispatch({
      type: 'NOTIFICATION_ACTION_REQUESTED',
      notification,
    }),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(NotificationsListItem));
