// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';

import { login, getUserData } from '../../modules/Auth/actions/';

type Props = {
  cookies: {
    get: string => string,
    remove: string => void,
  },
  login: ({ email: string, password: string }) => Promise<string>,
  history: {
    push: string => void,
  },
  getUserData: () => Promise<string>,
};

export class AutoLogin extends React.Component<Props> {
  componentDidMount() {
    if (
      //wait for user to confirm
      this.props.location.pathname !== '/verification/confirmation' &&
      this.props.cookies.get('rctl')
    ) {
      const loginValues = this.props.cookies.get('rctl').split(':');
      this.props
        .login({
          password: atob(loginValues[0]),
          email: atob(loginValues[1]),
        })
        .then(() => {
          this.props.cookies.remove('rctl');
          this.props.history.push('/');
          this.props.getUserData();
        })
        .catch(e => {
          if (e.name === 'UserNotConfirmedException') {
            this.props.history.push('/verification');
          } else if (e.name === 'NotAuthorizedException') {
            this.props.history.push('/login');
          } else {
            throw e;
          }
        });
    }
  }
  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  login: user => dispatch(login(user)),
  getUserData: () => dispatch(getUserData()),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withCookies(AutoLogin))
);
