// @flow

export const getEnvVar = (name: string, defaultVal: string = '') =>
  process.env[`REACT_APP_${name}`] || defaultVal;

export const WEB_BASE = getEnvVar('WEB_BASE', '');
export const USER_REDIRECT_WEB_BASE = getEnvVar('USER_REDIRECT_WEB_BASE', '');
export const API_BASE = getEnvVar('API_BASE', '');
export const GUEST_IDENTITY = getEnvVar('GUEST_IDENTITY', '');
export const API_ACCEPT_HEADER = getEnvVar('API_ACCEPT_HEADER', '');

export const USER_POOL_ID = getEnvVar('USER_POOL_ID', '');
export const CLIENT_ID = getEnvVar('CLIENT_ID', '');
export const AWS_REGION = getEnvVar('AWS_REGION', '');
export const ENVIRONMENT = getEnvVar('ENVIRONMENT', '');
export const IDENTITY_POOL_ID = getEnvVar('IDENTITY_POOL_ID', '');
export const VALIDATION_TYPE = getEnvVar('VALIDATION_TYPE', 'email');
export const ORGANIZATION_ID = getEnvVar('ORGANIZATION_ID', '');
export const GUEST_CLIENT_ID = getEnvVar('GUEST_CLIENT_ID', '');
export const GUEST_SECRET = getEnvVar('GUEST_SECRET', '');
export const GUEST_SCOPE = getEnvVar('GUEST_SCOPE', '');
export const GUEST_GRANT_TYPE = getEnvVar('GUEST_GRANT_TYPE', '');

export const NOTIFS_LONG_POLLING = getEnvVar('NOTIFS_LONG_POLLING', 'on');
export const FACEBOOK_APP_ID = getEnvVar('FACEBOOK_APP_ID', '');
export const SUPPORT_URL = getEnvVar('SUPPORT_URL', '');
export const REGION = getEnvVar('REGION', 'EU');

export const OAUTH2_PROVIDER = getEnvVar('OAUTH2_PROVIDER', '');
export const OAUTH2_PROVIDER_REDIRECT = getEnvVar(
  'OAUTH2_PROVIDER_REDIRECT',
  ''
);
export const OAUTH2_PROVIDER_REDIRECT_LOGOUT= getEnvVar(
  'OAUTH2_PROVIDER_REDIRECT_LOGOUT',
  ''
);

export const PROVIDER_FACEBOOK_LINK = getEnvVar('PROVIDER_FACEBOOK_LINK', '');
export const PROVIDER_FACEBOOK_LOGOUT_LINK = getEnvVar(
  'PROVIDER_FACEBOOK_LOGOUT_LINK',
  ''
);

export const FIREBASE_WEB_API_KEY = getEnvVar('FIREBASE_WEB_API_KEY', '');
export const FIREBASE_URL = getEnvVar('FIREBASE_URL', '');
export const MOBILE_GOING_TO = getEnvVar('MOBILE_GOING_TO', '');
export const MOBILE_ANDROID_PACKAGE_NAME = getEnvVar(
  'MOBILE_ANDROID_PACKAGE_NAME',
  ''
);
export const MOBILE_IOS_BOUNDLE_ID = getEnvVar('MOBILE_IOS_BOUNDLE_ID', '');
export const MOBILE_IOS_APP_STORE_ID = getEnvVar('MOBILE_IOS_APP_STORE_ID', '');
export const GTM_ID = getEnvVar('GTM_ID', '');
export const IPDATA_API_KEY = getEnvVar('IPDATA_KEY', '');

export const ID_TOKEN_COOKIE = 'idToken';

export const MAX_IMAGE_UPLOAD_SIZE = 4194304;

export const GOAL_PRODUCT_SEARCH_URL = getEnvVar('GOAL_PRODUCT_SEARCH_URL', '');
export const GOAL_PRODUCT_OFFERS_URL = getEnvVar('GOAL_PRODUCT_OFFERS_URL', '');
