import * as notificationsService from '../services';
import notif from '../../Notif/services';
import i18n from '../../../i18n';
import { PAGE_SIZE } from '../services';

export function notificationsSuccess(notifications) {
  return {
    type: 'NOTIFICATIONS_SUCCESS',
    notifications,
    pageSize: PAGE_SIZE,
  };
}

export function notificationsDeleteSuccess(id) {
  return {
    type: 'NOTIFICATIONS_DELETE_SUCCESS',
    id,
  };
}

export function notifsCounterSuccess(counter) {
  return {
    type: 'NOTIFICATIONS_COUNTER_POLLING_DONE',
    counter,
  };
}

export function notifsFetchInProgress() {
  return {
    type: 'NOTIFICATIONS_FETCH_PROGRESS',
  };
}

export function clearList() {
  return {
    type: 'NOTIFICATIONS_CLEAR_LIST',
  };
}

export const fetchNotifications = page => dispatch => {
  dispatch(notifsFetchInProgress());
  return notificationsService.getNotifications(page).then(notifications => {
    dispatch(notificationsSuccess(notifications));
    return notifications;
  });
};

export const deleteNotification = id => dispatch => {
  return notificationsService.deleteNotification(id).then(() => {
    dispatch(notificationsDeleteSuccess(id));
    dispatch(fetchNotifications());
    notif.success(i18n.t('notifications.delete-success'));
  });
};

export const fetchNotifsCounter = () => dispatch => {
  return notificationsService.getNotificationsCount().then(counterData => {
    const counter =
      typeof counterData.notificationCounter !== 'undefined'
        ? counterData.notificationCounter
        : 0;
    dispatch(notifsCounterSuccess(counter));
  });
};
