// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';
import NotifToastr from '../../modules/Notif/components/NotifToastr';
import CookieCheck from '../../modules/Notif/components/CookieCheck';
import ErrorBoundary from '../../components/ErrorBoundary';

import './styles.css';

type Props = {
  children?: React.Node | string,
};

const MixedLayout = (props: Props) => (
  <React.Fragment>
    <Loader />
    <header className="mixed header">
      <NotifToastr />
      <CookieCheck />
      <div className="header__container">
        <Link to="/events" ta-id="header_logo">
          <div className="header__logo" />
        </Link>
        <Navigation />
      </div>
    </header>
    <main className="content content--private">
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </main>
    <Footer className="mixed" />
  </React.Fragment>
);

export default MixedLayout;
