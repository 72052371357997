import { getCountries, getCountriesBank, getNationalities } from '../services';

export function countriesSuccess(countries) {
  return {
    type: 'COUNTRIES_DATA_FETCH_SUCCESS',
    countries,
  };
}

export function countriesBankSuccess(countries) {
  return {
    type: 'COUNTRIES_BANK_DATA_FETCH_SUCCESS',
    countries,
  };
}

export function nationalitiesSuccess(nationalities) {
  return {
    type: 'NATIONALITIES_DATA_FETCH_SUCCESS',
    nationalities,
  };
}

export function selectCountry(countryCode) {
  return {
    type: 'COUNTRIES_SELECTED_COUNTRY',
    countryCode,
  };
}

export const fetchCountries = () => dispatch =>
  getCountries().then(countries => {
    dispatch(countriesSuccess(countries));
  });

export const fetchCountriesBank = () => dispatch =>
  getCountriesBank().then(countries => {
    dispatch(countriesBankSuccess(countries));
  });

export const fetchNationalities = () => dispatch =>
  getNationalities().then(nationalities => {
    dispatch(nationalitiesSuccess(nationalities));
  });
