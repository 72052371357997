import React, { Fragment } from 'react';
import Loadable from 'react-loadable';

import PrivateLayout from '../../../layout/Private';
import PrivateRoute from '../../../components/PrivateRoute';
import PublicLayout from '../../../layout/Mixed';
import PublicRoute from '../../../components/PublicRoute';
import Loading from '../../../components/Loading';
import * as constants from '../../../services/constants';

const region = constants.REGION;

const CreateEvent = Loadable({
  loader: () => import('../components/CreateEvent'),
  loading: Loading,
});

const EditEvent = Loadable({
  loader: () => import('../components/EditEvent'),
  loading: Loading,
});

const EventsList = Loadable({
  loader: () => import('../components/EventsList'),
  loading: Loading,
});

const EventDetails = Loadable({
  loader: () => import('../components/EventDetails'),
  loading: Loading,
});

const InviteFriends = Loadable({
  loader: () => import('../components/InviteFriends'),
  loading: Loading,
});

const JoinEvent = Loadable({
  loader: () => import('../components/JoinEvent'),
  loading: Loading,
});

const ContributeCardEU = Loadable({
  loader: () => import('../components/ContributeCardEU'),
  loading: Loading,
});

const ContributeCardUS = Loadable({
  loader: () => import('../components/ContributeCardUS'),
  loading: Loading,
});

const ContributeIdeal = Loadable({
  loader: () => import('../components/ContributeIdeal'),
  loading: Loading,
});

const Contribute = Loadable({
  loader: () => import('../components/Contribute'),
  loading: Loading,
});

const CashOut = Loadable({
  loader: () => import('../components/CashOut'),
  loading: Loading,
});

const CashOutReceiver = Loadable({
  loader: () => import('../components/CashOutReceiver'),
  loading: Loading,
});

const CashOutEmail = Loadable({
  loader: () => import('../components/CashOutEmail'),
  loading: Loading,
});

const CashOutBank = Loadable({
  loader: () => import('../components/CashOutBank'),
  loading: Loading,
});

const CashOutDebit = Loadable({
  loader: () => import('../components/CashOutDebit'),
  loading: Loading,
});

const PaymentReturn = Loadable({
  loader: () => import('../components/PaymentReturn'),
  loading: Loading,
});

const PaymentSuccess = Loadable({
  loader: () => import('../components/PaymentSuccess'),
  loading: Loading,
});

const EventWishList = Loadable({
  loader: () => import('../components/EventWishList'),
  loading: Loading,
});

const NotFound = Loadable({
  loader: () => import('../../../modules/HomePage/components/NotFound'),
  loading: Loading,
});

const EventsRoutes = (
  <Fragment>
    <PrivateRoute
      exact
      path="/events"
      layout={PrivateLayout}
      component={EventsList}
    />
    <PrivateRoute
      exact
      path="/events/create"
      layout={PrivateLayout}
      component={CreateEvent}
    />
    <PrivateRoute
      exact
      path="/events/:id/edit"
      layout={PrivateLayout}
      component={EditEvent}
    />
    <PrivateRoute
      exact
      path="/events/:id"
      layout={PrivateLayout}
      component={EventDetails}
    />
    <PrivateRoute
      exact
      path="/events/:uuid/details"
      layout={PrivateLayout}
      component={EventDetails}
    />
    <PrivateRoute
      exact
      path="/events/:id/invite"
      layout={PrivateLayout}
      component={InviteFriends}
    />
    <PrivateRoute
      exact
      path="/events/:id/cash-out"
      layout={PrivateLayout}
      component={CashOut}
    />
    <PrivateRoute
      exact
      path="/events/:eventUuid/invitations/:invitationUuid/spend"
      layout={PrivateLayout}
      component={CashOutReceiver}
    />
    <PrivateRoute
      exact
      path="/events/:id/cash-out/email"
      layout={PrivateLayout}
      component={CashOutEmail}
    />
    <PrivateRoute
      exact
      path="/events/:id/cash-out/bank"
      layout={PrivateLayout}
      component={CashOutBank}
    />
    <PrivateRoute
      exact
      path="/events/:id/cash-out/debit"
      layout={PrivateLayout}
      component={CashOutDebit}
    />
    <PublicRoute
      exact
      path="/events/:id/join/organizationId/:organizationId"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={JoinEvent}
    />
    <PublicRoute
      exact
      path="/events/:id/join/uden/:amountToContribute"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={JoinEvent}
    />
    <PublicRoute
      exact
      path="/events/:id/:invitationId/join"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={JoinEvent}
    />
    <PublicRoute
      exact
      path="/events/:id/join"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={JoinEvent}
    />
    <PublicRoute
      exact
      path="/events/:id/contribute/card"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={region === 'EU' ? ContributeCardEU : ContributeCardUS}
    />
    <PublicRoute
      exact
      path="/events/:id/contribute/ideal"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={region === 'EU' ? ContributeIdeal : NotFound}
    />
    <PublicRoute
      exact
      path="/events/:id/:invitationId/contribute/card"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={region === 'EU' ? ContributeCardEU : ContributeCardUS}
    />
    <PublicRoute
      exact
      path="/events/:id/:invitationId/contribute/ideal"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={region === 'EU' ? ContributeIdeal : NotFound}
    />
    <PublicRoute
      exact
      path="/events/:id/contribute"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={region === 'EU' ? Contribute : ContributeCardUS}
    />
    <PublicRoute
      exact
      path="/events/:id/:invitationId/contribute"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={region === 'EU' ? Contribute : ContributeCardUS}
    />
    <PublicRoute
      path="/payment-return"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={PaymentReturn}
    />
    <PublicRoute
      path="/events/:id/contribute/success"
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={PaymentSuccess}
    />
    <PrivateRoute
      exact
      path="/events/:id/wishlist"
      layout={PrivateLayout}
      component={EventWishList}
    />
    EventWishList
  </Fragment>
);

export default EventsRoutes;
