import { combineReducers } from 'redux';

function dynamicLink(state = null, action) {
  switch (action.type) {
    case 'DYNAMIC_LINK_SUCCESS':
      return action.link;
    default:
      return state;
  }
}

export default combineReducers({
  dynamicLink,
});
