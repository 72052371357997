// @flow

import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import CoachMarkDetails from './CoachMarkDetails';
import { makeUserUuidState } from '../../selectors/accepted';
import { extensionLink } from '../../services/extensionLink';
import mobileUtils from '../../../../startupUtils/mobile'

import './styles.css';

type Props = {
  page: string,
  remove: () => void,
  coachmarkAccept: string => Promise<string>,
  cookies: {
    set: (string, string) => void,
  },
  uuid: string,
};

class Coachmark extends Component<Props> {
  removeHandler = () => {
    const { cookies, page, remove, uuid } = this.props;
    cookies.set(`${uuid}_coachmark_${page}`, 'accepted');
    remove.call();
    this.props.coachmarkAccept(page);
  };
  installExtension = () => {
    extensionLink() && window.open(extensionLink(), '_blank');
    this.removeHandler();
  };
  render() {
    return (
      <div className={`${mobileUtils.isOnMobile() ? "coachmark--mobile coachmark" : "coachmark"}`}>
        <CoachMarkDetails
          page={this.props.page}
          onAccept={this.removeHandler}
          onInstall={this.installExtension}
        />
      </div>
    );
  }
}

const makeMapDispatchToProps = () => {
  const getUserUuid = makeUserUuidState();
  const mapStateToProps = (state, props) => ({
    uuid: getUserUuid(state),
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  coachmarkAccept: (page: string) =>
    dispatch({ type: 'COACHMARK_ACCEPTED', page }),
});

export default connect(
  makeMapDispatchToProps,
  mapDispatchToProps
)(withCookies(Coachmark));
