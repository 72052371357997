import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';

import CreateButton from '../CreateButton';
import NotificationsNav from '../../../modules/Notifications/components/NotificationsNav';
import HeaderProfile from '../../../modules/Profile/components/HeaderProfile';

const pageFromLocation = location => {
  if (location) {
    const locations = location.split('/');
    if (locations.length > 1) {
      return location.split('/')[1];
    }
  }
  return null;
};

const isActive = (page, currentPage) =>
  page === currentPage ? ' header__navigation-list-item--active' : '';

const Navigation = ({ t, location }) => {
  const currentPage = pageFromLocation(location.pathname);
  return (
    <nav className="header__navigation">
      <ul className="header__navigation-list">
        <span className="header__navigation-list-item-lava" id="lava" />
        <li
          className={`header__navigation-list-item${isActive(
            'events',
            currentPage
          )}`}
        >
          <Link ta-id="header_events" to="/events">
            {t('home.header.events')}
          </Link>
        </li>
        <li
          className={`header__navigation-list-item${isActive(
            'goals',
            currentPage
          )}`}
        >
          <Link ta-id="header_goals" to="/goals">
            {t('home.header.goals')}
          </Link>
        </li>
        <li
          className={`header__navigation-list-item${isActive(
            'wishlists',
            currentPage
          )}`}
        >
          <Link ta-id="header_wishlists" to="/wishlists">
            {t('home.header.wishlists')}
          </Link>
        </li>
        <li className="header__navigation-list-item">
          <CreateButton page={currentPage} />
        </li>
        <li className="header__navigation-list-item header__navigation-list-item-notifications">
          <NotificationsNav />
        </li>
        <li className="header__navigation-list-item">
          <HeaderProfile />
        </li>
      </ul>
    </nav>
  );
};

export default withRouter(translate()(Navigation));
