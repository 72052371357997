// @flow

import React from 'react';
import TimeAgo from 'react-timeago';
import i18n from '../../i18n';
import nlStrings from 'react-timeago/lib/language-strings/nl';
import enStrings from 'react-timeago/lib/language-strings/en';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

type Props = {
  date: string,
};
let formatter;
i18n.on('languageChanged', lng => {
  formatter = buildFormatter(lng === 'nl-NL' ? nlStrings : enStrings);
});

const TimeSince = ({ date }: Props) => {
  return <TimeAgo date={date} formatter={formatter} />;
};

export default TimeSince;
