import { combineReducers } from 'redux';

function notificationSettings(state = {}, action) {
  switch (action.type) {
    case 'USER_NOTIFICATIONS_SUCCESS':
      return action.settings;
    default:
      return state;
  }
}

function user(state = null, action) {
  switch (action.type) {
    case 'PROFILE_USER_FETCH_SUCCESS':
      return action.user;
    default:
      return state;
  }
}

function bankAccount(state = null, action) {
  switch (action.type) {
    case 'PROFILE_BANK_ACCOUNT_FETCH_SUCCESS':
      return action.bankAccount;
    default:
      return state;
  }
}

function creditCard(state = null, action) {
  switch (action.type) {
    case 'CREDIT_CARD_FETCH_SUCCESS':
      return action.card.cardNumber ? action.card : null;
    case 'DELETE_CREDIT_CARD_SUCCESS':
      return null;
    default:
      return state;
  }
}

function debitCard(state = null, action) {
  switch (action.type) {
    case 'DEBIT_CARD_FETCH_SUCCESS':
      return action.card.cardNumber ? action.card : null;
    default:
      return state;
  }
}

export default combineReducers({
  notificationSettings,
  user,
  bankAccount,
  creditCard,
  debitCard,
});
