// @flow

import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { withCookies } from 'react-cookie';

import { isBrowserOutdated } from '../../services/utils';
import './styles.css';

const browserUpdateLink = 'http://browserupdate.org/update-browser.html';

type Props = {
  t: string => string,
  cookies: {
    set: (string, string, { expires: Date, path: string }) => void,
    get: string => string,
  },
};

type State = {
  showBrowserWarning: boolean,
};

class BrowserUpdate extends React.Component<Props, State> {
  state = {
    showBrowserWarning: false,
  };

  getExpirationDate = () => {
    return new Date(new Date().getTime() + 2592000);
  };

  browserWarningDismiss = () => {
    this.props.cookies.set('browser_warning_dismissed', 'true', {
      expires: this.getExpirationDate(),
      path: '/',
    });
    this.setState({
      showBrowserWarning: false,
    });
  };
  browserType = () => isBrowserOutdated().split(' ')[0];
  componentDidMount() {
    if (
      isBrowserOutdated() &&
      !this.props.cookies.get('browser_warning_dismissed')
    ) {
      this.setState({
        showBrowserWarning: true,
      });
    }
  }
  render() {
    const { t } = this.props;
    return (
      <Fragment>
        {this.state.showBrowserWarning && (
          <div className="browser-warning-banner">
            <div className="browser-warning-banner__content">
              <div
                className={`browser-warning-banner__icon browser-warning-banner__icon--${this.browserType()}`}
              />
              <div>
                {`${t('global.browser-warning-1')} ( ${isBrowserOutdated()} ) `}
              </div>
              <div>
                {`${t('global.browser-warning-2')} `}
                <a
                  href={browserUpdateLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('global.update')}
                </a>
                {` ${t('global.browser-warning-3')}`}
              </div>
              <button
                type="button"
                className="browser-warning-banner__dismiss"
                onClick={this.browserWarningDismiss}
              >
                ✕
              </button>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withCookies(translate()(BrowserUpdate));
