import browserDetect from 'browser-detect';

const isMobile = path => {
  const browser = browserDetect();
  let mobileException = false;
  if (
    path.includes('verification/confirmation') ||
    path.includes('join') ||
    path.includes('contribute') ||
    path.includes('payment-return') ||
    path.includes('cookies') ||
    path.includes('terms-of-use') ||
    path.includes('privacy')
  ) {
    mobileException = true;
  }
  return browser.mobile && !mobileException;
};

const isOnMobile = () => {
  const browser = browserDetect();
  return browser.mobile;
};

const getMobileType = () => {
  const browser = browserDetect();
  const browserOS = browser.os;
  if (browserOS.includes('Android')) {
    return 'android';
  }
  if (browserOS.includes('OS X')) {
    return 'ios';
  }
  return 'other';
};

const slideToggle = (target, close = false) => {
  if (close) {
    target.style.height = 0;
    target.classList.remove('active');
    return;
  }
  const header = document.querySelector('header');
  const height = window.innerHeight - header.offsetHeight;
  target.style.height = `${height}px`;
  target.style.top = `${header.offsetHeight}px`;
  if (target.classList.contains('active')) {
    target.style.height = 0;
    target.classList.remove('active');
  } else {
    target.classList.add('active');
  }
};

const filterSlideToggle = (target, height) => {
  target.style.height = `${height}px`;
  if (target.classList.contains('show-filters')) {
    target.style.height = 0;
    target.classList.remove('show-filters');
  } else {
    target.classList.add('show-filters');
  }
};

const mobileUtils = {
  isMobile: isMobile,
  isOnMobile: isOnMobile,
  getMobileType: getMobileType,
  slideToggle: slideToggle,
  filterSlideToggle: filterSlideToggle,
};

export default mobileUtils;
