// @flow

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getUserBasicData } from '../../../modules/Auth/selectors/authSelector';
import UserPhoto from '../../../modules/Profile/components/HeaderProfile/UserPhoto';
import './styles.css';

function MobileProfile({ user }) {
  return (
    <div className="profile-menu">
      <UserPhoto photo={user.imageUrl} />
      <div>
        <div className="profile-menu__user-name">
          {`${user.firstName} ${user.lastName}`}
        </div>
        <div className="profile-menu__user-email">{user.email}</div>
      </div>
    </div>
  );
}

const makeMapStateToProps = state => {
  const mapStateToProps = state => {
    return {
      user: getUserBasicData(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(translate()(MobileProfile));
