import { combineReducers } from 'redux';
import * as R from 'ramda';

function occasionTypes(state = [], action) {
  switch (action.type) {
    case 'OCCASION_TYPES_SUCCESS':
      return action.types;
    default:
      return state;
  }
}

function list(state = null, action) {
  switch (action.type) {
    case 'EVENTS_LIST_SUCCESS': {
      return state ? [...state, ...action.events] : [...action.events];
    }
    case 'CLEAR_EVENTS_LIST':
      return null;
    case 'DELETE_EVENT_SUCCESS':
      let index = state.map(item => item.event.id).indexOf(action.id);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    case 'EVENTS_REQ_WHISHLIST_SUCCESS':
      if (state) {
        index = state.map(item => item.event.id).indexOf(action.id);
        return [
          ...state.slice(0, index),
          action.event,
          ...state.slice(index + 1),
        ];
      }
      return state;
    default:
      return state;
  }
}

function hasMore(state = false, action) {
  switch (action.type) {
    case 'EVENTS_LIST_SUCCESS':
      return action.events.length === action.pageSize;
    case 'EVENTS_FETCH_PROGRESS':
      return false;
    default:
      return state;
  }
}

function pageIndex(state = 1, action) {
  switch (action.type) {
    case 'EVENTS_LIST_SUCCESS':
      return state + 1;
    case 'CLEAR_EVENTS_LIST':
      state = 1;
      return state;
    default:
      return state;
  }
}

function currentEvent(state = null, action) {
  switch (action.type) {
    case 'EVENT_SUCCESS':
      return action.event;
    case 'EVENT_RESET':
      return null;
    case 'DELETE_EVENT_SUCCESS':
      return null;
    default:
      return state;
  }
}

function eventDetails(state = null, action) {
  switch (action.type) {
    case 'EVENT_DETAILS_SUCCESS':
      return action.eventDetails;
    case 'EVENT_RESET':
      return null;
    case 'DELETE_EVENT_SUCCESS':
      return null;
    default:
      return state;
  }
}

function createEvent(state = null, action) {
  switch (action.type) {
    case 'CREATE_EVENT_SUCCESS':
      return action.event;
    default:
      return state;
  }
}

function newEvent(state = null, action) {
  switch (action.type) {
    case 'NEW_EVENT_SUCCESS':
      return action.event;
    case 'EVENT_RESET':
      return null;
    default:
      return state;
  }
}

function messages(state = [], action) {
  switch (action.type) {
    case 'MESSAGES_SUCCESS':
      return R.uniqBy(a => a.id, state.concat(action.messages));
    case 'POLLER_MESSAGES_SUCCESS':
      return R.uniqBy(a => a.id, action.messages.concat(state));
    case 'MESSAGES_RESET':
      return action.messages;
    case 'EDIT_COMMENT_SUCCESS':
      const index = R.findIndex(R.propEq('id', action.id))(state);
      const newState = state.slice(0);
      newState[index].messageBody = action.message;
      return newState;
    case 'DELETE_COMMENT_SUCCESS':
      return R.reject(R.propEq('id', action.id))(state);
    default:
      return state;
  }
}

function totalPages(state = false, action) {
  switch (action.type) {
    case 'TOTAL_PAGES_SUCCESS':
      return action.total;
    default:
      return state;
  }
}

function totalComments(state = null, action) {
  switch (action.type) {
    case 'MESSAGES_TOTAL':
      return action.total;
    case 'MESSAGES_DECREASE':
      return state - 1;
    default:
      return state;
  }
}

function invitees(state = [], action) {
  switch (action.type) {
    case 'INVITEES_SUCCESS':
      return action.invitees;
    default:
      return state;
  }
}
function participants(state = [], action) {
  switch (action.type) {
    case 'PARTICIPANTS_SUCCESS':
      return action.participants;
    case 'PARTICIPANTS_FAIL':
      return state;
    default:
      return state;
  }
}

function pendingInvitees(state = [], action) {
  switch (action.type) {
    case 'PENDING_INVITEES_SUCCESS':
      return action.pendingInvitees;
    case 'PENDING_INVITEES_FAIL':
      return state;
    default:
      return state;
  }
}

function contributors(state = [], action) {
  switch (action.type) {
    case 'CONTRIBUTORS_SUCCESS':
      return action.contributors;
    default:
      return state;
  }
}

function creditCard(state = null, action) {
  switch (action.type) {
    case 'CREDIT_CARD_FETCH_SUCCESS':
      return action.card.cardNumber ? action.card : null;
    case 'DELETE_CREDIT_CARD_SUCCESS':
      return null;
    default:
      return state;
  }
}

function debitCard(state = null, action) {
  switch (action.type) {
    case 'DEBIT_CARD_FETCH_SUCCESS':
      return action.card.cardNumber ? action.card : null;
    case 'DELETE_DEBIT_CARD_SUCCESS':
      return null;
    default:
      return state;
  }
}

function cashOutProfile(state = null, action) {
  switch (action.type) {
    case 'PROFILE_DATA_NEXT': {
      return action.profileData;
    }
    case 'PROFILE_DATA_NEXT_CLEAR': {
      return null;
    }
    default:
      return state;
  }
}

function cashOutCard(state = null, action) {
  switch (action.type) {
    case 'CARD_DATA_NEXT': {
      return action.cardData || state;
    }
    case 'CARD_DATA_CLEAR':
    case 'CARD_DATA_NEXT_CLEAR': {
      return null;
    }
    default:
      return state;
  }
}

function profileFormVisible(state = false, action) {
  switch (action.type) {
    case 'TOGGLE_PROFILE_FORM': {
      return action.show;
    }
    default:
      return state;
  }
}

function invitation(state = null, action) {
  switch (action.type) {
    case 'EVENT_REQUESTED_WISHLIST_SUCCESS':
      return action.invitation;
    default:
      return state;
  }
}

function goals(state = null, action) {
  switch (action.type) {
    case 'EVENT_WISHLIST_GOALS':
      return action.goals;
    default:
      return state;
  }
}

function permissions(state = null, action) {
  switch (action.type) {
    case 'EVENT_PERMISSIONS_SUCCESS':
      return action.permissions;
    default:
      return state;
  }
}

function requestWishlistStatus(state = '', action) {
  switch (action.type) {
    case 'WISHLIST_REQUEST_STATUS':
      return action.status;
    default:
      return state;
  }
}

export default combineReducers({
  occasionTypes,
  list,
  createEvent,
  creditCard,
  debitCard,
  hasMore,
  pageIndex,
  currentEvent,
  eventDetails,
  newEvent,
  messages,
  contributors,
  invitees,
  participants,
  pendingInvitees,
  totalComments,
  totalPages,
  cashOutProfile,
  cashOutCard,
  profileFormVisible,
  invitation,
  goals,
  permissions,
  requestWishlistStatus,
});
