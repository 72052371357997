import React, { Fragment } from 'react';
import Loadable from 'react-loadable';

import PrivateLayout from '../../../layout/Private';
import PrivateRoute from '../../../components/PrivateRoute';
import Loading from '../../../components/Loading';
import mobileUtils from '../../../startupUtils/mobile';

const GoalsList = Loadable({
  loader: () => import('../components/GoalsList'),
  loading: Loading,
});

const CreateGoal = Loadable({
  loader: () => import('../components/CreateGoal'),
  loading: Loading,
});

const EditGoal = Loadable({
  loader: () => import('../components/EditGoal'),
  loading: Loading,
});

const GoalDetails = Loadable({
  loader: () => import('../components/GoalDetails'),
  loading: Loading,
});


const GoalDetailsMobile = Loadable({
  loader: () => import('../components/GoalDetailsMobile'),
  loading: Loading,
});

const GoalsRoutes = (
  <Fragment>
    <PrivateRoute
      path="/goals"
      exact
      layout={PrivateLayout}
      component={GoalsList}
    />
    <PrivateRoute
      path="/goals/create"
      exact
      layout={PrivateLayout}
      component={CreateGoal}
    />
    <PrivateRoute
      path="/goals/:uuid/edit"
      exact
      layout={PrivateLayout}
      component={EditGoal}
    />
    <PrivateRoute
      path="/goals/:uuid"
      exact
      layout={PrivateLayout}
      component={mobileUtils.isOnMobile() ? GoalDetailsMobile : GoalDetails}
    />
  </Fragment>
);

export default GoalsRoutes;
