// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
} from 'react-simple-dropdown';
import NotificationsNavList from '../NotificationsNavList';
import makeCounterState from '../../selectors/notifsCounterSelectors';
import * as notifsActions from '../../actions';
import * as constants from '../../../../services/constants';

import './styles.css';

type Props = {
  notificationsCount: number,
  pollNotifsCounter: () => void,
};

const longPolling = constants.NOTIFS_LONG_POLLING;

class NotificationsNav extends Component<Props> {
  interval = undefined;
  static defaultProps = {
    notificationsCount: 0,
  };

  startPoll = () => {
    this.interval = setInterval(() => this.props.pollNotifsCounter(), 30000);
  };

  componentDidMount() {
    this.props.pollNotifsCounter();
    const enablePolling = longPolling;

    if (enablePolling === 'on') {
      this.startPoll();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateCounter = () =>
    this.props.notificationsCount > 100 ? '99+' : this.props.notificationsCount;

  handleLinkClick = () => {
    this.refs.notificationsDropdown.hide();
  };
  closeMobileNav = () => {};

  render() {
    return (
      <Dropdown ref="notificationsDropdown" removeElement>
        <DropdownTrigger>
          <div
            className="notifications-menu"
            ta-id="header_notifications"
            onClick={
              this.props.isMobile &&
              this.props.ariaExpanded ?
              this.props.toggleNav : undefined
            }
          >
            <div className="notifications-menu__count">
              {this.calculateCounter()}
            </div>
          </div>
        </DropdownTrigger>
        <DropdownContent>
          <NotificationsNavList handleLinkClick={this.handleLinkClick} />
        </DropdownContent>
      </Dropdown>
    );
  }
}

const makeMapStateToProps = () => {
  const userNotifsCounter = makeCounterState();
  const mapStateToProps = (state, props) => {
    return {
      notificationsCount: userNotifsCounter(state),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  pollNotifsCounter: () => dispatch(notifsActions.fetchNotifsCounter()),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(NotificationsNav);
