import React, { Fragment } from 'react';
import Loadable from 'react-loadable';

import PrivateLayout from '../../../layout/Private';
import PrivateRoute from '../../../components/PrivateRoute';
import Loading from '../../../components/Loading';

const NotificationsList = Loadable({
  loader: () => import('../components/NotificationsList'),
  loading: Loading,
});

const NotificationsRoutes = (
  <Fragment>
    <PrivateRoute
      exact
      path="/notifications"
      layout={PrivateLayout}
      component={NotificationsList}
    />
  </Fragment>
);

export default NotificationsRoutes;
