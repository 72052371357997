import { combineReducers } from 'redux';

function occasionTypes(state = [], action) {
  switch (action.type) {
    case 'LISTI_OCCASION_TYPES_SUCCESS':
      return action.types;
    default:
      return state;
  }
}

function listiData(state = {}, action) {
  switch (action.type) {
    case 'GET_LISTI_DATA':
      return action.data;
    case 'CLEAR_LISTI_DATA':
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  occasionTypes,
  listiData,
});
