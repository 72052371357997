import React, { Fragment } from 'react';
import Loadable from 'react-loadable';

import PrivateLayout from '../../../layout/Private';
import PrivateRoute from '../../../components/PrivateRoute';
import Loading from '../../../components/Loading';

const Settings = Loadable({
  loader: () => import('../components/SettingsContainer'),
  loading: Loading,
});

const ProfileRoutes = (
  <Fragment>
    <PrivateRoute
      exact
      path="/settings"
      layout={PrivateLayout}
      component={Settings}
    />
  </Fragment>
);

export default ProfileRoutes;
