const isSuccessAction = type => type.substring(type.length - 8) === '_SUCCESS';

const isFailAction = type => type.substring(type.length - 5) === '_FAIL';

export default (state = 0, action) => {
  if (action.type === 'BEGIN_AJAX_CALL') {
    return state + 1;
  } else if (
    action.type === 'AJAX_CALL_ERROR' ||
    isSuccessAction(action.type) ||
    isFailAction(action.type)
  ) {
    return state > 0 ? state - 1 : 0;
  }

  return state;
};
