import jwt_decode from 'jwt-decode';
import cognito from '../../../services/cognito.js';
import api, { apiFederate } from '../../../api';
import * as constants from '../../../services/constants';
import cookieUtils from '../../../services/cookies';

const appId = constants.CLIENT_ID;
const redirect = constants.OAUTH2_PROVIDER_REDIRECT;
const fbLogoutLink = constants.PROVIDER_FACEBOOK_LOGOUT_LINK;
const provider = constants.OAUTH2_PROVIDER;
const idTokenCookie = constants.ID_TOKEN_COOKIE;

export const setVerificationEmail = email =>
  localStorage.setItem('verification_resend_email', email);

export const removeVerificationEmail = () =>
  localStorage.removeItem('verification_resend_email');

export const getCurrentUser = () =>
  cognito.getCurrentUser().then(response => {
    setTokenCookie(response.id_token);
    return response;
  });

export const login = user => {
  return cognito
    .signIn(user)
    .then(() => {
      removeVerificationEmail();
    })
    .catch(e => {
      if (e.name === 'UserNotConfirmedException') {
        setVerificationEmail(user.email);
        cookieUtils.setCookie(
          'rctl',
          `${btoa(user.password)}:${btoa(user.email)}`,
          10
        );
        throw e;
      }
    });
};

const setTokenCookie = token => {
  cookieUtils.setCookie(idTokenCookie, token, 60);
};

const deleteTokenCookie = () => {
  cookieUtils.removeCookie(idTokenCookie);
};

export const register = (user, locale) => {
  return cognito.signUp(user, locale).then(response => {
    setVerificationEmail(user.email);
    cookieUtils.setCookie(
      'rctl',
      `${btoa(user.password)}:${btoa(user.email)}`,
      10
    );
    return response;
  });
};

export const forgotPassword = email => {
  return cognito.forgotPassword(email);
};

export const updatePassword = values => cognito.changePassword(values);

export const resetPassword = values => cognito.resetPassword(values);

export const confirmRegistration = values =>
  cognito.confirmRegistration(values);

export const getUserData = () => {
  return api({
    method: 'get',
    url: `me/users`,
  });
};

export const logOut = (history, redirect) => {
  cognito.signOut();
  deleteTokenCookie();
  if (window.localStorage.loginType === 'fb') {
    window.location.href = encodeURI(provider + fbLogoutLink);
  } else {
    history.push(`/${redirect}`);
  }
  localStorage.clear();
};

export const getFederateTokens = code => {
  const data = `grant_type=authorization_code&code=${code}&client_id=${appId}&redirect_uri=${encodeURI(
    redirect
  )}`;
  return apiFederate({
    method: 'post',
    url: `oauth2/token`,
    data: data,
  });
};

export const setFederateTokens = tokens => {
  const userId = jwt_decode(tokens.id_token).sub;
  window.localStorage.loginType = 'fb';
  window.localStorage[
    `CognitoIdentityServiceProvider.${appId}.${userId}.idToken`
  ] = tokens.id_token;
  window.localStorage[
    `CognitoIdentityServiceProvider.${appId}.${userId}.accessToken`
  ] = tokens.access_token;
  window.localStorage[
    `CognitoIdentityServiceProvider.${appId}.${userId}.refreshToken`
  ] = tokens.refresh_token;
  window.localStorage[
    `CognitoIdentityServiceProvider.${appId}.LastAuthUser`
  ] = userId;
};

export const setNewFederateTokens = session => {
  const userId = jwt_decode(session.getIdToken().getJwtToken()).sub;
  window.localStorage[
    `CognitoIdentityServiceProvider.${appId}.${userId}.idToken`
  ] = session.getIdToken().getJwtToken();
  window.localStorage[
    `CognitoIdentityServiceProvider.${appId}.${userId}.accessToken`
  ] = session.getAccessToken().getJwtToken();
  window.localStorage[
    `CognitoIdentityServiceProvider.${appId}.${userId}.refreshToken`
  ] = session.getRefreshToken().getToken();
};

export const resendConfirmationCode = () => cognito.resendConfirmationCode();
