import axios from 'axios';
import cookieUtils from './cookies';
import * as constants from './constants';

const guestAppId = constants.GUEST_CLIENT_ID;
const guestAppSecret = constants.GUEST_SECRET;
const apiBase = constants.GUEST_IDENTITY;
const scope = constants.GUEST_SCOPE;
const grantType = constants.GUEST_GRANT_TYPE;

const getToken = () => {
  const token = cookieUtils.getCookie('guestToken');
  if (token.length) {
    return token;
  } else {
    const bearer = btoa(`${guestAppId}:${guestAppSecret}`);
    const axiosInstance = axios.create({
      baseURL: apiBase,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${bearer}`,
      },
    });
    const data = `grant_type=${grantType}&scope=${encodeURIComponent(scope)}`;
    const options = {
      method: 'post',
      url: 'oauth2/token',
      data: data,
    };
    return axiosInstance(options)
      .then(response => {
        cookieUtils.setCookie('guestToken', response.data.access_token, 30);
        return response.data.access_token;
      })
      .catch(error => console.log(error));
  }
};

export default getToken;
