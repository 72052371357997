// @flow

import React from 'react';
import { translate } from 'react-i18next';

import './styles.css';

type Props = {
  t: string => string,
};

const EmptyNotifications = ({ t, emptyClassName }: Props) => (
  <div className="empty-notifications">
    <p>{t(`notifications.empty-state.title`)}</p>
    <div className="empty-notifications__image" />
  </div>
);

export default translate()(EmptyNotifications);
