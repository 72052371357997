// @flow
import React from 'react';
import { Trans } from 'react-i18next';
import { translate } from 'react-i18next';

type Props = {
  amount: ?number,
  t: string => string,
};

const ValidationMaxAmount = ({ amount, t }: Props) => (
  <Trans i18nKey="notifications.mango.kyc" amount={amount}>
    With this contribution you will exceed our {{ currency: t('currency') }}
    {{ amount }} yearly contributions limit. Please{' '}
    <a href="/settings">Identify Yourself</a> first in order to contribute
    more."
  </Trans>
);

ValidationMaxAmount.defaultProps = {
  amount: 2500,
};

export default translate()(ValidationMaxAmount);
