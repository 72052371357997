import React, { useState, useEffect, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router';
import MobileProfile from './MobileProfile';
import i18n from '../../i18n';
import Button from '../../components/Button';
import LanguageSelector from '../../components/LanguageSelector';
import * as constants from '../../services/constants';
import mobileUtils from '../../startupUtils/mobile';
import CreateButton from '../Private/CreateButton';
import NotificationsNav from '../../modules/Notifications/components/NotificationsNav';
import { hasEngagementPermissions } from '../../modules/Admin/services';
import { getUserRoles } from '../../modules/Auth/selectors/authSelector';
import './styles.css';
const region = constants.REGION;
const supportUrl = constants.SUPPORT_URL;

function MobileNavigation({ t, location, isPrivate, roles, history }) {
  const [toggleAriaExpanded, setAriaExpanded] = useState(false);
  // const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const mobileNavigationList = useRef(null);

  useEffect(() => {}, []);

  useEffect(
    () => {
      mobileNavigationList.current.style.height = 0;
      history.listen(() => {
        closeNav();
      });
    },

    []
  );

  const toggleNav = () => {
    mobileUtils.slideToggle(mobileNavigationList.current);
    setAriaExpanded(!toggleAriaExpanded);
  };

  const closeNav = () => {
    if (mobileNavigationList.current) {
      mobileUtils.slideToggle(mobileNavigationList.current, true);
      setAriaExpanded(false);
    }
  };

  const pageFromLocation = location => {
    if (location) {
      const locations = location.split('/');
      if (locations.length > 1) {
        return location.split('/')[1];
      }
    }
    return null;
  };

  const currentPage = pageFromLocation(history.location.pathname);
  const isActive = (page, currentPage) =>
    page === currentPage ? ' mobile__navigation-list-item--active' : '';

  return (
    <nav className="mobile__navigation">
      {!isPrivate ? (
        <Link
          to="/sign-up"
          ta-id="mobile_create-account"
          className="mobile__create-account"
        >
          <Button
            testSelector="mobile_signup"
            className="button button--primary button--signup"
          >
            {t('home.header.create-account')}
          </Button>
        </Link>
      ) : (
        <div className="mobile__top-navigation">
          <CreateButton page={currentPage} />
          <NotificationsNav
            isMobile={true}
            toggleNav={toggleNav}
            ariaExpanded={toggleAriaExpanded}
          />
        </div>
      )}
      <div
        aria-expanded={toggleAriaExpanded}
        className="mobile__navigation-toggler"
        onClick={() => toggleNav()}
        type="button"
      >
        <span className="icon-bar" />
        <span className="icon-bar" />
        <span className="icon-bar" />
      </div>

      <ul className="mobile__navigation-list" ref={mobileNavigationList}>
        {!isPrivate ? (
          <Fragment>
            <li
              className={`mobile__navigation-list-item${isActive(
                'about',
                currentPage
              )}`}
            >
              <Link ta-id="mobile_about" to="/about">
                {t('home.header.about-us')}
              </Link>
            </li>
            <li className="mobile__navigation-list-item">
              <a
                ta-id="mobile_faq"
                href={`${supportUrl}${
                  i18n.language === 'nl-NL' ? 'nl' : 'en'
                }/support/home`}
              >
                {t('home.header.faq')}
              </a>
            </li>
            <li
              className={`mobile__navigation-list-item${isActive(
                'login',
                currentPage
              )} login-link`}
            >
              <Link to="/login" ta-id="mobile_login">
                {t('home.header.login')}
              </Link>
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li className="mobile__navigation-list-item mobile__navigation-list-item--profile">
              <MobileProfile />
            </li>
            <li
              className={`mobile__navigation-list-item${isActive(
                'events',
                currentPage
              )}`}
            >
              <Link ta-id="mobile_events" to="/events">
                {t('home.header.events')}
              </Link>
            </li>
            <li
              className={`mobile__navigation-list-item${isActive(
                'goals',
                currentPage
              )}`}
            >
              <Link ta-id="mobile_goals" to="/goals">
                {t('home.header.goals')}
              </Link>
            </li>
            <li
              className={`mobile__navigation-list-item${isActive(
                'wishlists',
                currentPage
              )}`}
            >
              <Link ta-id="mobile_wishlists" to="/wishlists">
                {t('home.header.wishlists')}
              </Link>
            </li>
            <li
              className={`mobile__navigation-list-item${isActive(
                'settings',
                currentPage
              )}`}
            >
              <Link to="/settings" ta-id="mobile_settings">
                {t('profile.menu.settings')}
              </Link>
            </li>
            {roles &&
              hasEngagementPermissions(roles) && (
                <li className="mobile__navigation-list-item engagement">
                  <Link
                    to="/notifications/engagement"
                    ta-id="mobile_engagement-notifications"
                  >
                    {t('profile.menu.engagement-notifications')}
                  </Link>
                </li>
              )}
            <li className="mobile__navigation-list-item logout-link">
              <Link to="/logout" ta-id="mobile_logout">
                {t('profile.menu.log-out')}
              </Link>
            </li>
          </Fragment>
        )}

        <li className="mobile__navigation-list-item language-selector">
          {region === 'EU' && (
            <div>
              <span className="language-selector--title">
                {t('home.header.change-language')}
              </span>
              <LanguageSelector language={i18n.language} />
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
}

const makeMapStateToProps = state => {
  const mapStateToProps = (state, props) => {
    return {
      roles: getUserRoles(state, props),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  withRouter(translate()(MobileNavigation))
);
