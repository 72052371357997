// @flow
import React, { Fragment } from 'react';
import Button from '../../../../../components/Button';
import { translate } from 'react-i18next';

type Props = {
  page: string,
  t: string => string,
  onAccept: () => void,
};

const CoachmarkDetails = ({ page, t, onAccept, onInstall }: Props) => {
  return (
    <Fragment>
      <div className={`coachmark__icon coachmark__icon--${page}`} />

      <div className="coachmark__text">
        <div className="coachmark__title">
          {t(`home.coachmark.${page}.title`)}
        </div>
        <div className="coachmark__body">
          {t(`home.coachmark.${page}.body`)}
        </div>
      </div>
      <div className="coachmark__actions">
        {page &&
          page === 'goal-details' && (
            <Button
              className="button button--primary button--small-size"
              onClick={onInstall}
              ta-id="coachmark_extension"
            >
              {t('home.coachmark.action.install-extension')}
            </Button>
          )}
        <Button
          className={`button ${
            page === 'goal-details' ? 'button--invisible' : 'button--primary'
          } button--small-size`}
          onClick={onAccept}
          ta-id="coachmark_got-it"
        >
          {page === 'goal-details'
            ? t('home.coachmark.action.dismiss')
            : t('home.coachmark.action.accept')}
        </Button>
      </div>
    </Fragment>
  );
};

export default translate()(CoachmarkDetails);
