import axios from 'axios';
import i18n from '../../../i18n';

export const getCountries = () => {
  return axios
    .get(`//${window.location.host}/locales/${i18n.language}/countries.json`)
    .then(response =>
      response.data.map(c => ({
        label: c.text,
        value: c.id,
        regions: c.regions ? c.regions : null,
      }))
    );
};

export const getCountriesBank = () => {
  return axios
    .get(
      `//${window.location.host}/locales/${i18n.language}/countriesBank.json`
    )
    .then(response =>
      response.data.map(c => ({
        label: c.text,
        value: c.id,
        regions: c.regions ? c.regions : null,
      }))
    );
};

export const getNationalities = () => {
  return axios
    .get(
      `//${window.location.host}/locales/${i18n.language}/nationalities.json`
    )
    .then(response => response.data.map(n => ({ label: n.text, value: n.id })));
};
