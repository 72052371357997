// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
} from 'react-simple-dropdown';

import {
  getUserBasicData,
  getUserRoles,
} from '../../../Auth/selectors/authSelector';
import UserPhoto from './UserPhoto';
import { hasEngagementPermissions } from '../../../Admin/services';

import i18n from '../../../../i18n';
import * as constants from '../../../../services/constants';
import './styles.css';

type Props = {
  t: string => string,
  user: {
    firstName: string,
    lastName: string,
    imageUrl: string,
    email: string,
  },
};

const supportUrl = constants.SUPPORT_URL;

class HeaderProfile extends Component<Props> {
  handleLinkClick = () => {
    this.refs.profileDropdown.hide();
  };
  render() {
    const { user, t } = this.props;
    return (
      <Dropdown ref="profileDropdown">
        <DropdownTrigger>
          <UserPhoto photo={user.imageUrl} />
        </DropdownTrigger>
        <DropdownContent>
          <div className="header__navigation profile-menu">
            <div className="profile-menu__user-name">
              {`${user.firstName} ${user.lastName}`}
            </div>
            <div className="profile-menu__user-email">{user.email}</div>
            <div className="profile-menu__link">
              <a
                href={`${supportUrl}${
                  i18n.language === 'nl-NL' ? 'nl' : 'en'
                }/support/home`}
                onClick={this.handleLinkClick}
              >
                {t('profile.menu.faq')}
              </a>
            </div>
            <div className="profile-menu__link">
              <Link
                to="/settings"
                ta-id="header_settings"
                onClick={this.handleLinkClick}
              >
                {t('profile.menu.settings')}
              </Link>
            </div>
            {this.props.roles &&
              hasEngagementPermissions(this.props.roles) && (
                <div className="profile-menu__link">
                  <Link
                    to="/notifications/engagement"
                    ta-id="header_engagement-notifications"
                    onClick={this.handleLinkClick}
                  >
                    {t('profile.menu.engagement-notifications')}
                  </Link>
                </div>
              )}
            <div className="profile-menu__link" onClick={this.handleLinkClick}>
              <Link to="/logout" ta-id="header_logout">
                {t('profile.menu.log-out')}
              </Link>
            </div>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  }
}

const makeMapStateToProps = state => {
  const mapStateToProps = (state, props) => {
    return {
      user: getUserBasicData(state),
      roles: getUserRoles(state, props),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(translate()(HeaderProfile));
