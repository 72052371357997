// @flow
import * as React from 'react';

import { Link } from 'react-router-dom';

import Navigation from '../Public/Navigation';
import MobileNavigation from '../MobileNavigation';
import Footer from '../../components/Footer';
import NotifToastr from '../../modules/Notif/components/NotifToastr';
import Loader from '../../components/Loader';
import CookieCheck from '../../modules/Notif/components/CookieCheck';
import ErrorBoundary from '../../components/ErrorBoundary';
import mobileUtils from '../../startupUtils/mobile';

import './styles.css';

type Props = {
  children?: React.Node | string,
};

const AuthLayout = (props: Props) => (
  <React.Fragment>
    <header className="auth header">
      <NotifToastr />
      <CookieCheck />
      <Loader />
      <div
        className={`${
          mobileUtils.isOnMobile() ? 'header__container--mobile' : ''
        } header__container`}
      >
        <Link to="/">
          <div className="header__logo" />
        </Link>
        {mobileUtils.isOnMobile() ? <MobileNavigation isPrivate={false} /> : <Navigation />}
      </div>
    </header>
    <main className="content content--private">
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </main>
    <Footer className="auth" />
  </React.Fragment>
);

export default AuthLayout;
