import { all, call, takeEvery } from 'redux-saga/effects';
import history from '../../../services/history';
import { getPublicWishlist } from '../services';
import notif from '../../Notif/services';
import i18n from '../../../i18n';

function* eventWLSharing(action) {
  try {
    yield call(getPublicWishlist, action.wishlistUuid);
    yield call(history.push, `/wishlist-public/${action.wishlistUuid}`);
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      notif.warning(i18n.t('wishlists.public.is-deleted'));
    }
  }
}

export function* watchWishlists() {
  yield all([takeEvery('NOTIF_WISHLIST_SHARING', eventWLSharing)]);
}
