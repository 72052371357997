import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import sentry from './sentry';
import calls from './calls';
import events from '../modules/Events/reducers';
import wishLists from '../modules/WishLists/reducers';
import auth from '../modules/Auth/reducers';
import mobileLanding from '../modules/MobileLanding/reducers';
import goals from '../modules/Goals/reducers';
import notifications from '../modules/Notifications/reducers';
import profile from '../modules/Profile/reducers';
import countries from '../modules/Countries/reducers';
import coachmark from '../modules/Coachmark/reducers';
import listi from '../modules/Listi/reducers';

export default combineReducers({
  sentry,
  calls,
  events,
  goals,
  wishLists,
  auth,
  mobileLanding,
  notifications,
  profile,
  countries,
  coachmark,
  form: reduxFormReducer,
  toastr: toastrReducer,
  listi,
});
