import { createSelector } from 'reselect';
import * as constants from '../../../services/constants';

const region = constants.REGION;

const getUserState = state => state && state.auth.user;

export const getUserData = state => state && state.auth.userData;

export const getUserOrg = createSelector([getUserState], user => {
  return user['custom:organization_id'];
});

export const getUserEmail = createSelector([getUserState], user => {
  return user.email;
});
export const getUserId = createSelector([getUserData], user => {
  return user.uuid;
});

export const getUserRoles = createSelector([getUserData], user => {
  return user.roles;
});

export const getUserBasicData = createSelector([getUserData], user => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    imageUrl: user.imageUrl,
    email: user.email,
  };
});

export const makeUserVerificationStatus = createSelector(
  [getUserState],
  user => user && user.email_verified
);

export const getUserLocale = createSelector([getUserData], user => {
  return (
    user &&
    user.locale && {
      // TODO: this will be removed once the backend is fixed
      locale:
        region === 'EU'
          ? user.locale === 'en_US'
            ? 'en-GB'
            : 'nl-NL'
          : 'en-US',
    }
  );
});
