import TagManager from 'react-gtm-module';
import { GTM_ID } from '../services/constants';

const googleAnalyticsInit = userId => {
  const tagManagerArgs = {
    gtmId: GTM_ID,
    dataLayer: {
      uid: btoa(userId),
    },
  };
  GTM_ID && TagManager.initialize(tagManagerArgs);
};

export default googleAnalyticsInit;
