// @flow
import React, { Component, Fragment } from 'react';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

const buttonFromPage = page => {
  switch (page) {
    case 'events':
      return {
        linkTo: '/events/create',
        label: 'events.create.action',
        iconClass: 'create-icon--events',
      };
    case 'wishlists':
      return {
        linkTo: '/wishlists/create',
        label: 'wishlists.create.action',
        iconClass: 'create-icon--wishlists',
      };
    case 'goals':
      return {
        linkTo: '/goals/create',
        label: 'goals.create.action',
        iconClass: 'create-icon--goals',
      };
    default:
      return null;
  }
};
type Props = {
  t: string => string,
  page: string,
};

class CreateButton extends Component<Props> {
  render() {
    const createBtn = buttonFromPage(this.props.page);
    const { t } = this.props;
    const page = this.props.page.substring(0, this.props.page.length - 1);
    return (
      <Fragment>
        {createBtn && (
          <Link to={createBtn.linkTo}>
            <Button
              className="button button--primary button--large button--large-height"
              testSelector={`header_create-${page}`}
            >
              <div className={`create-icon ${createBtn.iconClass}`}>
                {t(createBtn.label)}
              </div>
            </Button>
          </Link>
        )}
      </Fragment>
    );
  }
}

export default translate()(CreateButton);
