// @flow

import React from 'react';
import { withCookies } from 'react-cookie';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeUserUuidState } from '../../../Coachmark/selectors/accepted';

import './styles.css';

type Props = {
  t: string => string,
  remote: () => void,
  cookies: {
    set: (string, string) => void,
  },
  remove: () => void,
  uuid: string,
};

const removeHandler = (cookies, callback, uuid) => {
  cookies.set(`${uuid}_cookie_policy`, 'accepted', { path: '/' });
  callback.call();
};

const Cookies = (props: Props) => {
  return (
    <div className="cookie-toastr">
      <div className="cookie-toastr__info">
        {props.t('home.use-cookies.message')}
      </div>
      <div className="cookie-toastr__actions">
        <Link
          to="/cookies"
          className="cookie-toastr__actions--more-info"
          ta-id="cookies_more-info"
        >
          {props.t('home.use-cookies.more-info')}
        </Link>
        <button
          className="cookie-toastr__actions--confirm"
          ta-id="cookies_got-it"
          onClick={() => removeHandler(props.cookies, props.remove, props.uuid)}
        >
          {props.t('home.use-cookies.got-it')}
        </button>
      </div>
    </div>
  );
};

const makeMapStateToProps = () => {
  const getUserUuid = makeUserUuidState();
  const mapStateToProps = (state, props) => ({
    uuid: getUserUuid(state),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(withCookies(translate()(Cookies)));
