import * as authService from '../services';
import { beginCall, errorCall } from '../../../actions/calls';
import { selectCountry } from '../../Countries/actions';

export function getUserSuccess(isLoggedIn, user) {
  return {
    type: 'GET_USER_SUCCESS',
    isLoggedIn,
    user,
  };
}

export function getUserFail(isLoggedIn) {
  return {
    type: 'GET_USER_FAIL',
    isLoggedIn,
  };
}

export function getFbUserSuccess(isLoggedIn, user) {
  return {
    type: 'GET_USER_SUCCESS',
    isLoggedIn,
    user,
  };
}

export function getFbUserFail(isLoggedIn) {
  return {
    type: 'GET_USER_FAIL',
    isLoggedIn,
  };
}

export function loginSuccess(isLoggedIn, user) {
  return {
    type: 'LOGIN_SUCCESS',
    isLoggedIn,
    user,
  };
}

export function loginFail(isLoggedIn) {
  return {
    type: 'LOGIN_FAIL',
    isLoggedIn,
  };
}

export function registerSuccess(user) {
  return {
    type: 'REGISTER_SUCCESS',
    user,
  };
}

export function registerFail(user) {
  return {
    type: 'REGISTER_FAIL',
    user,
  };
}

export function userDataSuccess(userData) {
  return {
    type: 'USER_DATA_SUCCESS',
    userData,
  };
}

export function userDataFail(error) {
  return {
    type: 'USER_DATA_FAIL',
    error,
  };
}

export function forgotPasswordSuccess(response) {
  return {
    type: 'FORGOT_PASSWORD_SUCCESS',
    response,
  };
}

export function forgotPasswordFail(error) {
  return {
    type: 'FORGOT_PASSWORD_FAIL',
    error,
  };
}

export function updatePasswordSuccess() {
  return {
    type: 'UPDATE_PASSWORD_SUCCESS',
  };
}

export function logOutSuccess() {
  return {
    type: 'LOGOUT_SUCCESS',
  };
}

export function resetPasswordSuccess() {
  return {
    type: 'RESET_PASSWORD_SUCCESS',
  };
}

export function getUser(noLogin) {
  return dispatch => {
    return authService
      .getCurrentUser()
      .then(response => {
        dispatch(getUserSuccess(noLogin ? false : true, response));
      })
      .catch(error => {
        dispatch(getUserFail(false));
      });
  };
}

export const getUserData = () => {
  return dispatch => {
    return authService
      .getUserData()
      .then(response => {
        dispatch(selectCountry(response.data.countryCode));
        dispatch(userDataSuccess(response.data));
      })
      .catch(error => {
        dispatch(userDataFail());
      });
  };
};

export function login(user) {
  return dispatch => {
    dispatch(beginCall());
    return authService
      .login(user)
      .then(() => {
        return authService
          .getCurrentUser()
          .then(response => {
            dispatch(loginSuccess(true, response));
            dispatch(getUserSuccess(true, response));
          })
          .catch(e => {
            dispatch(getUserFail(false));
            throw e;
          });
      })
      .catch(e => {
        dispatch(loginFail(false));
        throw e;
      });
  };
}

export function register(user, locale) {
  return dispatch => {
    dispatch(beginCall());
    return authService
      .register(user, locale)
      .then(response => {
        dispatch(registerSuccess(response));
      })
      .catch(error => {
        dispatch(registerFail(error));
        throw error;
      });
  };
}

export function forgotPassword(email) {
  return dispatch => {
    dispatch(beginCall());
    return authService
      .forgotPassword(email)
      .then(response => {
        dispatch(forgotPasswordSuccess(response));
      })
      .catch(error => {
        dispatch(forgotPasswordFail(error));
        throw error;
      });
  };
}

export function updatePassword(values) {
  return dispatch => {
    dispatch(beginCall());
    return authService
      .updatePassword(values)
      .then(response => {
        dispatch(updatePasswordSuccess(response));
      })
      .catch(e => {
        dispatch(errorCall());
        throw e;
      });
  };
}

export function logOut(history, redirect = '') {
  return dispatch => {
    authService.logOut(history, redirect);
    return dispatch(logOutSuccess());
  };
}

export function resetPassword(values) {
  return dispatch => {
    dispatch(beginCall());
    return authService
      .resetPassword(values)
      .then(response => {
        dispatch(resetPasswordSuccess(response));
      })
      .catch(e => {
        dispatch(errorCall());
        throw e;
      });
  };
}
