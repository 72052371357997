// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRouterKey } from '../../services/utils';

import RouteWrapper from '../RouteWrapper';

type Props = {
  isLoggedIn: boolean,
  component: React.ComponentType<*>,
  layout: React.ComponentType<*>,
  location: {
    search: string,
    pathname: string,
  },
};

type State = {
  auth: {
    isLoggedIn: boolean,
  },
};

class PrivateRoute extends React.Component<Props, State> {
  render() {
    return this.props.isLoggedIn ? (
      <RouteWrapper
        {...this.props}
        component={this.props.component}
        layout={this.props.layout}
        key={getRouterKey(this.props.computedMatch, this.props.location)}
      />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: this.props.location },
        }}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(PrivateRoute);
