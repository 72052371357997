// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import MobileNavigation from '../MobileNavigation';
import Footer from '../../components/Footer';
import NotifToastr from '../../modules/Notif/components/NotifToastr';
import CookieCheck from '../../modules/Notif/components/CookieCheck';
import './styles.css';
import mobileUtils from '../../startupUtils/mobile';

type Props = {
  children?: React.Node | string,
};

export class PublicLayout extends React.Component<Props> {
  state = {
    headerFixed: false,
    showGoTopIcon: false,
  };
  componentDidMount() {
    const headerContainer = document.querySelector('.header__container');
    const contentPublic = document.querySelector('.content--public');

    const getMarginTop = () => {
      const publicFixed = document.querySelector('.public--fixed');
      contentPublic.style.marginTop = `${publicFixed.clientHeight}px`;
    };

    window.onscroll = () => {
      if (window.scrollY > headerContainer.offsetTop) {
        this.setState({ headerFixed: true }, () => getMarginTop());
      } else {
        this.setState({ headerFixed: false });
        contentPublic.style.marginTop = 0;
      }
      this.setState({ showGoTopIcon: window.scrollY > 500 });
    };
  }

  goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  render() {
    return (
      <React.Fragment>
        <header
          className={`public ${
            this.state.headerFixed ? 'public--fixed' : ''
          } header`}
        >
          <NotifToastr />
          <CookieCheck />
          <div
            className={`${
              mobileUtils.isOnMobile() ? 'header__container--mobile' : ''
            } header__container`}
          >
            <Link to="/" ta-id="header_logo">
              <div className="header__logo" />
            </Link>
            {mobileUtils.isOnMobile() ? (
              <MobileNavigation isPrivate={false} />
            ) : (
              <Navigation />
            )}
          </div>
          <span
            className={`go-top-icon ${
              this.state.showGoTopIcon ? 'show' : 'hide'
            }`}
            onClick={this.goTop}
          />
        </header>

        <div className="content content--public">{this.props.children}</div>
        <Footer className="public" />
      </React.Fragment>
    );
  }
}

export default PublicLayout;
