// @flow

import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getRouterKey } from '../../services/utils';
import RouteWrapper from '../RouteWrapper';

type Props = {
  component: React.ComponentType<*>,
  publicLayout: React.ComponentType<*>,
  privateLayout: React.ComponentType<*>,
  isLoggedIn: boolean,
  location: {
    search: string,
    pathname: string,
  },
};

type State = {
  auth: {
    isLoggedIn: boolean,
  },
};

class PublicRoute extends Component<Props, State> {
  render() {
    return (
      <RouteWrapper
        {...this.props}
        component={this.props.component}
        layout={
          this.props.isLoggedIn
            ? this.props.privateLayout
            : this.props.publicLayout
        }
        key={getRouterKey(this.props.computedMatch, this.props.location)}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(PublicRoute);
