// @flow
import React from 'react';
import { translate } from 'react-i18next';

import './styles.css';

type Props = {
  t: string => string,
  submitFeedback: () => void,
};

const translations = 'error-state.submit-feedback.';

const feedbackArr = [
  `${translations}title`,
  `${translations}subtitle2`,
  `${translations}labelName`,
  `${translations}labelEmail`,
  `${translations}labelComments`,
  `${translations}labelClose`,
  `${translations}labelSubmit`,
  `${translations}errorGeneric`,
  `${translations}errorFormEntry`,
  `${translations}successMessage`,
];

const ErrorState = ({ t, submitFeedback }: Props) => {
  return (
    <div className="error-state">
      <div className="error-state__image" />
      <h1 className="error-state__title">{t('error-state.title')}</h1>

      <p className="error-state__detail">
        {t('error-state.detail-1')}
        <br />
        {t('error-state.detail-2')}
      </p>
      <p className="error-state__retry">
        {t('error-state.retry')}
        <a href="/" onClick={e => submitFeedback(feedbackArr, t, e)}>
          {t('error-state.submit-feedback.link')}
        </a>
      </p>
    </div>
  );
};

export default translate()(ErrorState);
