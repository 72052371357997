import { combineReducers } from 'redux';
import * as R from 'ramda';

const initialState = {
  notifications: [],
  counter: 0,
  hasMore: false,
  pageIndex: 1,
};

export function notifications(state = initialState.notifications, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_SUCCESS':
      return R.uniqBy(x => x.id, [...state, ...action.notifications]).sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
      );
    case 'NOTIFICATIONS_DELETE_SUCCESS':
      const delIndex = state.map(item => item.id).indexOf(action.id);
      return [...state.slice(0, delIndex), ...state.slice(delIndex + 1)];
    case 'NOTIFICATION_ARCHIVE_SUCCESS':
      return state.map(
        notification =>
          notification.id === action.id
            ? { ...notification, archive: true }
            : notification
      );
    case 'LANGUAGE_CHANGED':
    case 'LOGOUT_SUCCESS':
      return initialState.notifications;
    default:
      return state;
  }
}

export function counter(state = initialState.counter, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_COUNTER_POLLING_DONE':
      return action.counter;
    default:
      return state;
  }
}

export function hasMore(state = initialState.hasMore, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_SUCCESS':
      return action.notifications.length === action.pageSize;
    case 'NOTIFICATIONS_FETCH_PROGRESS':
      return false;
    default:
      return state;
  }
}

export function pageIndex(state = initialState.pageIndex, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_SUCCESS':
      return state + 1;
    case 'NOTIFICATIONS_CLEAR_LIST':
      return initialState.pageIndex;
    default:
      return state;
  }
}

export default combineReducers({
  notifications,
  counter,
  hasMore,
  pageIndex,
});
