// @flow

import { Component } from 'react';
import type { Node } from 'react';
import { withCookies, Cookie } from 'react-cookie';
import { connect } from 'react-redux';

import notif from '../../services';
import { makeUserUuidState } from '../../../Coachmark/selectors/accepted';

type Props = {
  children?: Node | string,
  cookies: {
    get: string => Cookie,
  },
  uuid: string,
};

class CookieCheck extends Component<Props> {
  componentDidMount() {
    !this.props.cookies.get(`${this.props.uuid}_cookie_policy`) &&
      notif.cookies();
  }
  render() {
    return null;
  }
}
const mapStateToProps = state => ({
  uuid: makeUserUuidState()(state),
});

export default connect(mapStateToProps)(withCookies(CookieCheck));
