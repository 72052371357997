import React from 'react';
import ReduxToastr from 'react-redux-toastr';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './styles.css';

const NotifToastr = props => {
  return (
    <ReduxToastr
      newestOnTop={false}
      preventDuplicates
      position="top-left"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
    />
  );
};

export default NotifToastr;
