import { combineReducers } from 'redux';

function user(state = {}, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
    case 'GET_USER_SUCCESS':
      return action.user;
    case 'LOGIN_FAIL':
    case 'GET_USER_FAIL':
      return state;
    case 'LOGOUT_SUCCESS':
      return {};
    default:
      return state;
  }
}

function isLoggedIn(state = true, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
    case 'LOGIN_FAIL':
    case 'GET_USER_SUCCESS':
    case 'GET_USER_FAIL':
      return action.isLoggedIn;
    case 'LOGOUT_SUCCESS':
      return false;
    default:
      return state;
  }
}

function userData(
  state = { firstName: null, lastName: null, imageUrl: null, email: null },
  action
) {
  switch (action.type) {
    case 'USER_DATA_SUCCESS':
      return action.userData;
    case 'USER_DATA_FAIL':
      return state;
    case 'LOGOUT_SUCCESS':
      return { firstName: null, lastName: null, imageUrl: null, email: null };
    default:
      return state;
  }
}

export default combineReducers({
  user,
  isLoggedIn,
  userData,
});
