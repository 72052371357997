import React, { Fragment } from 'react';
import Loadable from 'react-loadable';
import Loading from '../../../components/Loading';

import PrivateLayout from '../../../layout/Private';
import PrivateRoute from '../../../components/PrivateRoute';

const WishLists = Loadable({
  loader: () => import('../components/WishLists'),
  loading: Loading,
});

const CreateWishList = Loadable({
  loader: () => import('../components/CreateWishList'),
  loading: Loading,
});

const WishListDetail = Loadable({
  loader: () => import('../components/WishListDetail'),
  loading: Loading,
});

const AddGoalsById = Loadable({
  loader: () => import('../components/AddGoalsById'),
  loading: Loading,
});

const ShareWishList = Loadable({
  loader: () => import('../components/ShareWishList'),
  loading: Loading,
});

const RequestedWishList = Loadable({
  loader: () => import('../components/RequestedWishList'),
  loading: Loading,
});

const WishlistGoalCreate = Loadable({
  loader: () => import('../components/WishlistGoalCreate'),
  loading: Loading,
});

const AddGoalsRequestedWL = Loadable({
  loader: () => import('../components/RequestedWishList/AddGoalsRequestedWL'),
  loading: Loading,
});

const WishListPublic = Loadable({
  loader: () => import('../components/WishListPublic'),
  loading: Loading,
});

const WishListRoutes = (
  <Fragment>
    <PrivateRoute
      path="/wishlists"
      exact
      layout={PrivateLayout}
      component={WishLists}
    />
    <PrivateRoute
      path="/wishlists/create"
      exact
      layout={PrivateLayout}
      component={CreateWishList}
    />
    <PrivateRoute
      path="/wishlists/:id/edit"
      exact
      layout={PrivateLayout}
      component={WishListDetail}
    />
    <PrivateRoute
      path="/wishlists/:id/add-goals"
      exact
      layout={PrivateLayout}
      component={AddGoalsById}
    />
    <PrivateRoute
      path="/wishlists/goals/create"
      exact
      layout={PrivateLayout}
      component={WishlistGoalCreate}
    />
    <PrivateRoute
      path="/wishlists/:id/share"
      exact
      layout={PrivateLayout}
      component={ShareWishList}
    />
    <PrivateRoute
      path="/requested-wishlist/noaccount"
      exact
      layout={PrivateLayout}
      component={RequestedWishList}
    />
    <PrivateRoute
      path="/requested-wishlist/nogoals"
      exact
      layout={PrivateLayout}
      component={RequestedWishList}
    />
    <PrivateRoute
      path="/requested-wishlist"
      exact
      layout={PrivateLayout}
      component={RequestedWishList}
    />
    <PrivateRoute
      path="/requested-wishlist/:eventUuid/:requestUuid/:id/add-goals"
      exact
      layout={PrivateLayout}
      component={AddGoalsRequestedWL}
    />
    <PrivateRoute
      path="/wishlist-public/:uuid/"
      exact
      layout={PrivateLayout}
      component={WishListPublic}
    />
  </Fragment>
);

export default WishListRoutes;
