import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import notif from '../modules/Notif/services';
import i18n from '../i18n';
import * as constants from '../services/constants';

const userPoolId = constants.USER_POOL_ID;
const clientId = constants.CLIENT_ID;
const awsRegion = constants.AWS_REGION;
const identityPoolId = constants.IDENTITY_POOL_ID;
const validationType = constants.VALIDATION_TYPE;

const parseError = error => {
  const { name, message } = error;
  return { name, message };
};

class CognitoService {
  constructor() {
    if (userPoolId) {
      this.userPool = new CognitoUserPool({
        ClientId: clientId,
        UserPoolId: userPoolId,
      });
      AWS.config.region = awsRegion;
      this.cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider(
        {
          apiVersion: '2016-04-18',
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
          }),
        }
      );
    }
  }

  createCognitoUser({ email }) {
    const userData = {
      Username: email.toLowerCase(),
      Pool: this.userPool,
    };

    return new CognitoUser(userData);
  }

  errorNotif(error) {
    if (typeof error.name !== 'undefined') {
      if (error.name === 'UserNotConfirmedException') {
        //user not confirmed does not necessary mean auth failed
        notif.warning(i18n.t(`notifications.auth.${error.name}`));
        return false;
      }
      if (error.name === 'LimitExceededException') {
        notif.warning(i18n.t('notifications.auth.limit-exceeded'));
        return false;
      }
      notif.error(
        i18n.t(`notifications.auth.${error.name}`),
        i18n.t('notifications.auth.failed')
      );
    } else {
      notif.error(i18n.t('notifications.auth.failed'));
    }
  }

  forgotPwdErrorNotif(error) {
    if (error.name && error.name === 'UserNotFoundException') {
      notif.info(i18n.t('notifications.auth.ForgotPasswordGeneric'));
    } else if (error.name && error.name === 'InvalidParameterException') {
      if (
        error.message &&
        error.message.includes('no registered/verified email')
      ) {
        //reset password on unverified account
        notif.warning(i18n.t('notifications.auth.password.cannot-reset'));
      } else {
        //might some other similar error, try handle errorNotif
        this.errorNotif(error);
      }
    } else {
      this.errorNotif(error);
    }
  }

  // no authentication required
  signUp(data, locale) {
    const organizationId =
      window.localStorage.getItem('organizationId') !== null
        ? JSON.parse(window.localStorage.getItem('organizationId'))
        : constants.ORGANIZATION_ID;
    const attrEmail = new CognitoUserAttribute({
      Name: 'email',
      Value: data.email.toLowerCase(),
    });
    const attrFirstName = new CognitoUserAttribute({
      Name: 'family_name',
      Value: data.lastName,
    });
    const attrLastName = new CognitoUserAttribute({
      Name: 'given_name',
      Value: data.firstName,
    });
    const attrLocale = new CognitoUserAttribute({
      Name: 'locale',
      Value: locale,
    });
    const attrTermsOfUse = new CognitoUserAttribute({
      Name: 'custom:terms_of_use',
      Value: data.terms_of_use.toString(),
    });
    const attrOrganization = new CognitoUserAttribute({
      Name: 'custom:organization_id',
      Value: organizationId,
    });

    const attributeList = [
      attrEmail,
      attrFirstName,
      attrLastName,
      attrLocale,
      attrOrganization,
      attrTermsOfUse,
    ];
    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        data.email.toLowerCase(),
        data.password,
        attributeList,
        null,
        (error, result) => {
          if (error) {
            reject(parseError(error));
            return;
          }
          resolve(result);
        }
      );
    });
  }

  signIn({ email, password }, dispatch) {
    return new Promise((resolve, reject) => {
      if (!email || !password) {
        reject(new Error('Email and password are required!'));
        return;
      }
      const lowerCaseEmail = email.toLowerCase();
      const authenticationData = {
        Username: lowerCaseEmail,
        Password: password,
      };

      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      const cognitoUser = this.createCognitoUser({ email: lowerCaseEmail });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          resolve(result);
        },
        onFailure: error => {
          this.errorNotif(error);
          reject(parseError(error));
        },
        // eslint-disable-next-line
        newPasswordRequired: function(result) {
          dispatch({ type: 'SHOW_NEW_PASSWORD', showNewPassword: true });
          document.getElementById('save-password').onclick = () => {
            const newPassword = document.getElementById('newPassword').value;
            // this.onSuccess = (res) => {
            //   dispatch({ type: 'SHOW_PASSWORD_SUCCESS', showPasswordSuccess: true });
            //   console.log(res);
            // };
            cognitoUser.completeNewPasswordChallenge(newPassword, {}, this);
          };
        },
        // eslint-disable-next-line
        mfaRequired: function() {
          dispatch({ type: 'SHOW_CODE', showCode: true });
          document.getElementById('code').onkeyup = e => {
            if (e.target.value.length === 6) {
              cognitoUser.sendMFACode(e.target.value, this);
            }
          };
          document.getElementById('resend-code').onclick = () => {
            cognitoUser.authenticateUser(authenticationDetails, this);
          };
        },
      });
    });
  }

  changePassword(values, dispatch) {
    return new Promise((resolve, reject) => {
      if (!values.oldPassword || !values.newPassword) {
        reject(new Error('Old and new passwords are required!'));
        return;
      }

      const cognitoUser = this.userPool.getCurrentUser();

      // getSession() loads valid tokens cached in the local store
      cognitoUser.getSession(error => {
        if (error) {
          reject(parseError(error));
          return;
        }

        cognitoUser.changePassword(
          values.oldPassword,
          values.newPassword,
          (_error, result) => {
            if (_error) {
              reject(parseError(_error));
              return;
            }

            resolve(result);
          }
        );
      });
    });
  }

  confirmRegistration({ username, code }) {
    return new Promise((resolve, reject) => {
      if (!username || !code) {
        reject(new Error('Email and code are required!'));
        return;
      }

      const cognitoUser = this.createCognitoUser({
        email: username.toLowerCase(),
      });

      cognitoUser.confirmRegistration(code, true, (error, result) => {
        if (error) {
          reject(parseError(error));
          return;
        }

        resolve(result);
      });
    });
  }

  resendConfirmationCode() {
    return new Promise((resolve, reject) => {
      const email = localStorage.getItem('verification_resend_email');
      if (!email) {
        reject(new Error('Email is required!'));
        return;
      }

      const lowerCaseEmail = email.toLowerCase();
      const cognitoUser = this.createCognitoUser({ email: lowerCaseEmail });

      cognitoUser.resendConfirmationCode((error, result) => {
        if (error) {
          reject(parseError(error));
          return;
        }
        resolve(result);
        notif.success(i18n.t('email-verification.success'));
      });
    });
  }

  forgotPassword({ email }) {
    return new Promise((resolve, reject) => {
      if (!email) {
        reject(new Error('Email is required!'));
        return;
      }

      const lowerCaseEmail = email.toLowerCase();
      const cognitoUser = this.createCognitoUser({ email: lowerCaseEmail });
      cognitoUser.forgotPassword({
        onSuccess: data => {
          resolve(data);
          notif.info(i18n.t('notifications.auth.ForgotPasswordGeneric'));
        },
        onFailure: error => {
          this.forgotPwdErrorNotif(error);
          reject(parseError(error));
        },
      });
    });
  }

  resetPassword({ email, code, password }) {
    return new Promise((resolve, reject) => {
      if (!email || !password) {
        reject(new Error('Email, code and password are required!'));
        return;
      }

      const lowerCaseEmail = email.toLowerCase();
      const cognitoUser = this.createCognitoUser({ email: lowerCaseEmail });

      cognitoUser.confirmPassword(code, password, {
        onSuccess: data => {
          resolve(data);
        },
        onFailure: error => {
          reject(parseError(error));
        },
      });
    });
  }

  // current user

  getCurrentUser() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new Error('No user found.'));
        return;
      }

      // getSession() loads valid tokens cached in the local store
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(parseError(error));
          return;
        }

        cognitoUser.getUserAttributes((_error, result) => {
          if (_error) {
            reject(parseError(_error));
            return;
          }

          const user = {
            id_token: session.getIdToken().getJwtToken(),
            access_token: session.getAccessToken().getJwtToken(),
            refresh_token: session.getRefreshToken().getToken(),
          };

          for (let i = 0; i < result.length; i += 1) {
            user[result[i].getName()] = result[i].getValue();
          }
          resolve(user);
        });
      });
    });
  }

  updateOrganizationId() {
    const organizationId =
      window.localStorage.getItem('organizationId') !== null
        ? window.localStorage.getItem('organizationId')
        : constants.ORGANIZATION_ID;
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new Error('No user found.'));
        return;
      }
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(parseError(error));
          return;
        }
        const attributeList = [];
        const attribute = new CognitoUserAttribute({
          Name: 'custom:organization_id',
          Value: organizationId,
        });
        attributeList.push(attribute);
        cognitoUser.updateAttributes(attributeList, (err, result) => {
          if (err) {
            reject(new Error(err));
          }
          resolve(result);
        });
      });
    });
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new Error('No user found.'));
        return;
      }
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(parseError(error));
          return;
        }
        const refresh_token = session.getRefreshToken();
        cognitoUser.refreshSession(refresh_token, (err, refSession) => {
          if (err) {
            reject(new Error(err));
          } else {
            resolve(refSession);
          }
        });
      });
    });
  }

  getTokens() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new Error('No user found.'));
        return;
      }

      // getSession() loads valid tokens cached in the local store
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(parseError(error));
          return;
        }

        const tokens = {
          id_token: session.getIdToken().getJwtToken(),
          access_token: session.getAccessToken().getJwtToken(),
          refresh_token: session.getRefreshToken().getToken(),
        };

        resolve(tokens);
      });
    });
  }

  deleteUser() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new Error('No user found.'));
        return;
      }

      cognitoUser.deleteUser((error, result) => {
        if (error) {
          reject(parseError(error));
          return;
        }

        resolve(result);
      });
    });
  }

  signOut = () => {
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) cognitoUser.signOut();
  };

  addUserToGroup(groupName) {
    const params = {
      GroupName: groupName,
      UserPoolId: userPoolId,
      Username: this.userPool.getCurrentUser().username,
    };
    return new Promise((resolve, reject) => {
      this.cognitoIdentityServiceProvider.adminAddUserToGroup(
        params,
        (error, result) => {
          if (error) {
            reject(parseError(error));
            return;
          }
          resolve(result);
        }
      );
    });
  }

  sendPhoneVerificationCode() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new Error('No user found.'));
        return;
      }
      // getSession() loads valid tokens cached in the local store
      cognitoUser.getSession(error => {
        if (error) {
          reject(parseError(error));
          return;
        }
        // TODO replace 'email' with 'phone_number' to enable phone validation
        cognitoUser.getAttributeVerificationCode(validationType, {
          onSuccess: data => {
            resolve(data);
          },
          onFailure: err => {
            reject(err);
          },
        });
      });
    });
  }

  verifyPhoneCode(code) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found.'));
        return;
      }
      // getSession() loads valid tokens cached in the local store
      cognitoUser.getSession(error => {
        if (error) {
          reject(parseError(error));
          return;
        }
        cognitoUser.verifyAttribute('phone_number', code, {
          onSuccess: data => {
            resolve(data);
          },
          onFailure: err => {
            reject(err);
          },
        });
      });
    });
  }
}

export default new CognitoService();
