import React, { Component, Fragment } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AutoLogin from './components/AutoLogin';
import BrowserUpdate from './components/BrowserUpdate';
import Loading from './components/Loading';
import RegionWarning from './components/RegionWarning';
import RouteWrapper from './components/RouteWrapper';
import FragmentSupportingSwitch from './FragmentSupportingSwitch';
import i18n from './i18n';
import PublicLayout from './layout/Public';
import AdminRoutes from './modules/Admin/routes/';
import { getUser, getUserData } from './modules/Auth/actions/';
import AuthRoutes from './modules/Auth/routes/';
import {
  getUserEmail,
  getUserId,
  getUserLocale,
} from './modules/Auth/selectors/authSelector.js';
import EventsRoutes from './modules/Events/routes/';
import GoalsRoutes from './modules/Goals/routes/';
import ListiRoutes from './modules/Listi/routes/';
import HomePageRoutes from './modules/HomePage/routes/';
import NotificationsRoutes from './modules/Notifications/routes';
import ProfileRoutes from './modules/Profile/routes';
import WishListsRoutes from './modules/WishLists/routes';
import { REGION } from './services/constants';
import { isBrowserUnsupported } from './services/utils';
import freshDeskInit from './startupUtils/freshDeskInit';
import googleAnalyticsInit from './startupUtils/googleAnalyticsInit';
import mobileUtils from './startupUtils/mobile';
import SentryInit from './startupUtils/sentryInit';
import './styles/app.css';
import './themes/theme.css';
const NotFound = Loadable({
  loader: () => import('./modules/HomePage/components/NotFound'),
  loading: Loading,
});
const NotSupported = Loadable({
  loader: () => import('./modules/HomePage/components/NotSupported'),
  loading: Loading,
});

class App extends Component {
  componentDidMount() {
    const path = this.props.history.location.pathname;
    this.props.getCurrentUser().then(() => {
      this.props.getUserData().then(() => {
        const locale = this.props.locale && this.props.locale.locale;
        i18n.changeLanguage(locale);
        googleAnalyticsInit(this.props.userId);
      });
      if (!mobileUtils.isMobile(path)) {
        freshDeskInit(REGION);
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.locale && this.props.locale.locale !== i18n.language) {
      i18n.changeLanguage(this.props.locale.locale);
    }
  }
  isBrowserUnsupported = () => isBrowserUnsupported();
  render() {
    return (
      <Fragment>
        <SentryInit region={REGION} />
        <RegionWarning />
        <BrowserUpdate />
        <AutoLogin />
        {this.isBrowserUnsupported() ? (
          <FragmentSupportingSwitch>
            {HomePageRoutes}
            <RouteWrapper layout={PublicLayout} component={NotSupported} />
          </FragmentSupportingSwitch>
        ) : (
          <FragmentSupportingSwitch>
            {HomePageRoutes}
            {AuthRoutes}
            {EventsRoutes}
            {GoalsRoutes}
            {WishListsRoutes}
            {NotificationsRoutes}
            {ProfileRoutes}
            {AdminRoutes}
            {ListiRoutes}
            <RouteWrapper layout={PublicLayout} component={NotFound} />
          </FragmentSupportingSwitch>
        )}
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    return {
      email: getUserEmail(state, props),
      locale: getUserLocale(state, props),
      userId: getUserId(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => dispatch(getUser()),
  getUserData: () => dispatch(getUserData()),
});

export default withRouter(
  connect(
    makeMapStateToProps,
    mapDispatchToProps
  )(App)
);
