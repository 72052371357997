// @flow

/* used */
export const emailRegExp = (value: string) =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
/* invite friends email regex !/^[A-Z0-9._%+-,@]+@[A-Z0-9.-]+\.[A-Z0-9._%+-,@]+$/i */
/* personal info form US email regex !/^[A-Za-z0-9- ]+$/ */
export const passwordRegExp = (value: string) =>
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{6,}$/i.test(value);
export const specialCharsRegExp = (value: string) => /[<>"']/.test(value);
export const specialCharsCompleteRegExp = (value: string) =>
  /[<>"'()]/.test(value);
export const htmlSafeRegExp = (value: string) => /[<>]/.test(value);
export const htmlRegExp = (value: string) => /<[a-z][\s\S]*>/i.test(value);
export const urlRegExp = (value: string) =>
  /([A-Za-z0-9][^www.\s]{1,})(\.([A-Za-z]{2,})){1,2}/.test(value);
export const postalCodeRegExp = (value: string) =>
  /^[a-zA-Z0-9\s-]+$/i.test(value);
export const cardExpDateRegExp = (value: string) =>
  /^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(value);

export const amountStrictPositive = (value: number | string) =>
  value &&
  parseFloat(
    value
      .toString()
      .replace(/\./g, '')
      .replace(',', '.')
  ) >= 1;

/* not used */
export const emailReg = /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
export const alphaNumRegExp = /^[a-zA-Z0-9 ]+$/i;
export const urlRegExpFlags = /([A-Za-z0-9][^www.\s]{2,})(\.([a-z]{2,})){1,2}/gim;

export const checkEmoji = (value: string) => {
  const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return regex.test(value) ? 'Emoji are not allowed' : undefined;
};
