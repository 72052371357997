// @flow

import * as React from 'react';
import { toastr } from 'react-redux-toastr';

import Cookies from '../components/Cookies';
import CoachmarkToastr from '../../Coachmark/components/CoachmarkToastr';

const toastrOptions: { icon: React.Node } = {
  icon: <span />,
};

const toastrConfirmOptions: {
  icon: React.Node,
  timeOut: number,
  removeOnHover: boolean,
  removeOnHoverTimeOut: number,
  showCloseButton: boolean,
  position: string,
  className: string,
  component: React.Node,
} = {
  icon: <span />,
  timeOut: 0,
  removeOnHover: false,
  removeOnHoverTimeOut: 0,
  showCloseButton: false,
  position: 'bottom-left',
  className: 'toastr--cookies',
  component: <Cookies />,
};

const toastrCoachmarkOptions: {
  icon: React.Node,
  timeOut: number,
  removeOnHover: boolean,
  removeOnHoverTimeOut: number,
  showCloseButton: boolean,
  position: string,
  className: string,
  component: React.Node,
} = {
  icon: <span />,
  timeOut: 0,
  removeOnHover: false,
  removeOnHoverTimeOut: 0,
  showCloseButton: false,
  position: 'bottom-left',
  className: 'toastr--coachmark',
  component: <React.Fragment />,
};

const notif = {};
notif.info = (title: string, description: ?string): void =>
  toastr.info(title, description, toastrOptions);

notif.warning = (title: string, description: ?string): void =>
  toastr.warning(title, description, toastrOptions);

notif.success = (title: string, description: ?string): void =>
  toastr.success(title, description, toastrOptions);

notif.error = (title: string, description: ?string): void =>
  toastr.error(title, description, toastrOptions);

notif.cookies = (): void => toastr.info('', '', toastrConfirmOptions);

notif.coachmark = (page: string): void => {
  toastrCoachmarkOptions.component = <CoachmarkToastr page={page} />;
  // add dummy title to coachmark toastr to differentiate between it and the
  // cookies toastr
  toastrCoachmarkOptions.className += ` ${page}`;
  return toastr.info('toastr coachmark', '', toastrCoachmarkOptions);
};

export default notif;
