import { combineReducers } from 'redux';

export function products(state = [], action) {
  switch (action.type) {
    case 'PRODUCT_FETCH_SUCCESS':
      return state.concat(action.response.products);
    case 'PRODUCT_RESET':
      return [];
    default:
      return state;
  }
}

export function productsNumber(state = null, action) {
  switch (action.type) {
    case 'PRODUCT_FETCH_SUCCESS':
      return action.response.offers;
    case 'CHANGE':
      return null;
    case 'PRODUCT_RESET':
      return null;
    default:
      return state;
  }
}

export function firstLoad(state = false, action) {
  switch (action.type) {
    case 'PRODUCT_FETCH_SUCCESS':
      return false;
    case 'PRODUCT_FIRST_LOAD':
      return true;
    case 'PRODUCT_RESET':
      return false;
    default:
      return state;
  }
}

function moreProducts(
  state = {
    fetchedProducts: 0,
    hasMore: false,
  },
  action
) {
  switch (action.type) {
    case 'PRODUCT_FETCH_SUCCESS':
      return {
        ...state,
        fetchedProducts: state.fetchedProducts + action.pageLimit,
        hasMore:
          state.fetchedProducts + action.pageLimit < action.response.offers,
      };
    case 'PRODUCT_RESET':
      return {
        ...state,
        fetchedProducts: 0,
        hasMore: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  products,
  productsNumber,
  moreProducts,
  firstLoad,
});
