import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import history from './services/history';
import store from './store';
import App from './app';
import * as serviceWorker from './serviceWorker';
import config from './swConfig';

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <Router history={history}>
        <App />
      </Router>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register(config);
