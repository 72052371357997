import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  getEventById,
  getRequestWishlist,
  getInviteeStatus,
  getPublicEventByUuid,
  resolveReceiverInvitation,
} from '../services';
import { joinEventSuccess } from '../actions';
import notif from '../../Notif/services';
import i18n from '../../../i18n';
import history from '../../../services/history';
import { REGION } from '../../../services/constants';

const isCachedOut = status => status === 'given';

function* joinEvent(action) {
  yield call(joinEventSuccess);
}

function* checkJoinEvent(action) {
  const { eventId } = action;
  let alreadyInvited = false;
  try {
    alreadyInvited = yield call(getInviteeStatus, eventId);
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.is-deleted'))
      );
    }
  }
  if (alreadyInvited.data) {
    yield call(
      history.push,
      `/events/${eventId}`,
      notif.warning(
        i18n.t('events.join.already-joined'),
        i18n.t('events.join.already-joined-desc')
      )
    );
  }
}

function* checkJoinEventSharing(action) {
  const { eventId, eventUuid, invitationUuid } = action;
  let alreadyInvited = false;
  try {
    alreadyInvited = yield call(getInviteeStatus, eventId);
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.is-deleted'))
      );
    }
  }
  if (alreadyInvited.data) {
    yield call(
      history.push,
      `/events/${eventId}`,
      notif.warning(
        i18n.t('events.join.already-joined'),
        i18n.t('events.join.already-joined-desc')
      )
    );
  } else {
    yield call(history.push, `/events/${eventUuid}/${invitationUuid}/join`);
  }
}

function* eventComment(action) {
  try {
    yield call(getEventById, action.eventId);
    yield call(history.push, `/events/${action.eventId}#tab0`);
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.is-deleted'))
      );
    }
  }
}

function* eventVote(action) {
  try {
    const event = yield call(getEventById, action.eventId);
    if (event.data && event.data.status === 'open') {
      yield call(history.push, `/events/${action.eventId}/wishlist`);
    } else {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('notifications.already-cashed-out'))
      );
    }
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.is-deleted'))
      );
    }
  }
}

function* eventCashOut(action) {
  try {
    const canCashOut = yield call(
      resolveReceiverInvitation,
      action.invitationUuid
    );
    if (canCashOut) {
      REGION === 'EU'
        ? yield call(history.push, `/events/${action.eventUuid}/cash-out/bank`)
        : yield call(history.push, `/events/${action.eventUuid}/cash-out`);
    } else {
      notif.error(i18n.t('notifications.auth.AccessDenied'));
      yield call(history.push, '/events');
    }
  } catch (error) {
    notif.error(i18n.t('notifications.auth.AccessDenied'));
    yield call(history.push, '/events');
  }
}

function* eventContribution(action) {
  yield call(history.push, `/events/${action.eventId}#tab1`);
}

function* eventAmountReached(action) {
  yield call(history.push, `/events/${action.eventId}`);
}

function* eventThreeDays(action) {
  try {
    const event = yield call(getEventById, action.eventId);
    if (event.status === 'open') {
      yield call(history.push, `/events/${action.eventId}/invite`);
    } else {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.cannot-invite-cached-out'))
      );
    }
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.is-deleted'))
      );
    }
  }
}

function* eventWLNoGoals(action) {
  try {
    const event = yield call(getPublicEventByUuid, action.eventUuid);
    if (event.data && isCachedOut(event.data.status)) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.already-decided-gift'))
      );
    } else {
      yield call(
        history.push,
        `/requested-wishlist?eventUuid=${action.eventUuid}&requestUuid=${
          action.requestUuid
        }`
      );
    }
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.is-deleted'))
      );
    }
  }
}

function* eventTimeLeftCachedOut(action) {
  try {
    const event = yield call(getEventById, action.eventId);
    if (isCachedOut(event.status)) {
      yield call(history.push, `/events/${action.eventID}/cache-out`);
    } else {
      yield call(history.push, `/events/${action.eventId}`);
    }
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.is-deleted'))
      );
    }
  }
}

function* eventWLCreated(action) {
  try {
    const requestWishlist = yield call(getRequestWishlist, action.eventId);

    const { event } = requestWishlist.data;

    if (event.status === 'open') {
      yield call(history.push, `/events/${action.eventId}/wishlist`);
    } else {
      yield call(
        history.push,
        `/events/${action.eventId}`,
        notif.warning(i18n.t('events.is-closed'))
      );
    }
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 404) {
      yield call(
        history.push,
        '/events',
        notif.warning(i18n.t('events.is-deleted'))
      );
    }
  }
}

function* createNewEvent(action) {
  const { event } = action;
  yield put({ type: 'NEW_EVENT_SUCCESS', event });
  yield call(history.push, '/events/create');
}

export function* watchEvents() {
  yield all([
    takeEvery('JOIN_EVENT_REQUESTED', joinEvent),
    takeEvery('JOIN_EVENT_CHECK', checkJoinEvent),
    takeEvery('CREATE_EVENT_REQUESTED', createNewEvent),
    takeEvery('NOTIF_EVENT_AMOUNT_REACHED', eventAmountReached),
    takeEvery('NOTIF_EVENT_CASH_OUT', eventCashOut),
    takeEvery('NOTIF_EVENT_CONTRIBUTION', eventContribution),
    takeEvery('NOTIF_EVENT_COMMENT', eventComment),
    takeEvery('NOTIF_EVENT_SHARING', checkJoinEventSharing),
    takeEvery('NOTIF_EVENT_TIME_LEFT_TO_CASH_OUT', eventTimeLeftCachedOut),
    takeEvery('NOTIF_EVENT_TIME_LEFT_THREE_DAYS', eventThreeDays),
    takeEvery('NOTIF_EVENT_VOTE', eventVote),
    takeEvery('NOTIF_EVENT_WISHLIST_REQUEST_NO_GOALS', eventWLNoGoals),
    takeEvery('NOTIF_EVENT_WISHLIST_CREATED', eventWLCreated),
  ]);
}
