import React, { Fragment } from 'react';
import Loadable from 'react-loadable';

import PrivateLayout from '../../../layout/Private';
import PrivateRoute from '../../../components/PrivateRoute';
import Loading from '../../../components/Loading';

const EngagementNotifications = Loadable({
  loader: () => import('../components/EngagementNotifications'),
  loading: Loading,
});

const AdminRoutes = (
  <Fragment>
    <PrivateRoute
      exact
      path="/notifications/engagement"
      layout={PrivateLayout}
      component={EngagementNotifications}
    />
  </Fragment>
);

export default AdminRoutes;
