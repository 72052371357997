import React, { Fragment } from 'react';
import Loadable from 'react-loadable';

import PublicLayout from '../../../layout/Public';
import RouteWrapper from '../../../components/RouteWrapper';
import Loading from '../../../components/Loading';

const HomePage = Loadable({
  loader: () => import('../components/HomePage'),
  loading: Loading,
});

const AboutUs = Loadable({
  loader: () => import('../components/AboutUs'),
  loading: Loading,
});

const Cookies = Loadable({
  loader: () => import('../components/Cookies'),
  loading: Loading,
});

const Privacy = Loadable({
  loader: () => import('../components/Privacy'),
  loading: Loading,
});

const Terms = Loadable({
  loader: () => import('../components/Terms'),
  loading: Loading,
});

const HomePageRoutes = (
  <Fragment>
    <RouteWrapper exact path="/" layout={PublicLayout} component={HomePage} />
    <RouteWrapper path="/about" layout={PublicLayout} component={AboutUs} />
    <RouteWrapper path="/cookies" layout={PublicLayout} component={Cookies} />
    <RouteWrapper path="/privacy" layout={PublicLayout} component={Privacy} />
    <RouteWrapper
      path="/terms-of-use"
      layout={PublicLayout}
      component={Terms}
    />
  </Fragment>
);

export default HomePageRoutes;
