import { combineReducers } from 'redux';

function countries(state = [], action) {
  switch (action.type) {
    case 'COUNTRIES_DATA_FETCH_SUCCESS':
      return action.countries;
    default:
      return state;
  }
}

function countriesBank(state = [], action) {
  switch (action.type) {
    case 'COUNTRIES_BANK_DATA_FETCH_SUCCESS':
      return action.countries;
    default:
      return state;
  }
}

function nationalities(state = [], action) {
  switch (action.type) {
    case 'NATIONALITIES_DATA_FETCH_SUCCESS':
      return action.nationalities;
    default:
      return state;
  }
}

function selected(state = null, action) {
  switch (action.type) {
    case 'COUNTRIES_SELECTED_COUNTRY':
      return action.countryCode;
    default:
      return state;
  }
}
export default combineReducers({
  countries,
  countriesBank,
  nationalities,
  selected
});
