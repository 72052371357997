import React from 'react';
import { translate } from 'react-i18next';
import { apiHealthCheck } from '../../api';
import './styles.css';

const retry = () => {
  //retry api status by reaload page for now
  window.location.reload(true);
};

class Maintenance extends React.Component<Props> {
  componentWillMount() {
    let healthyCount = 0;
    const intervalId = setInterval(() => {
      apiHealthCheck()
        .then(response => {
          if (response && response.message) {
            if (response.message.includes('success')) {
              healthyCount++;
              if (healthyCount > 2) {
                retry();
              }
            }
          }
        })
        .catch(e => {
          healthyCount = 0;
        });
    }, 10000);
    this.setState({
      intervalId,
    });
  }
  componentWillUnmount() {
    this.state.intervalId && clearInterval(this.state.intervalId);
  }
  render() {
    const { t } = this.props;
    return (
      <div className="maintenance">
        <h1 className="maintenance__title">{t('maintenance.title')}</h1>
        <p className="maintenance__detail">
          {t('maintenance.detail-1')} <br /> {t('maintenance.detail-2')}
        </p>
        <button
          className="maintenance__retry button button--primary"
          onClick={() => retry()}
        >
          {t('maintenance.retry')}
        </button>
        <div className="maintenance__image" />
      </div>
    );
  }
}
export default translate()(Maintenance);
