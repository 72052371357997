import { combineReducers } from 'redux';
import * as R from 'ramda';

function lists(state = { content: [] }, action) {
  switch (action.type) {
    case 'WISHLISTS_LISTS_SUCCESS':
      return {
        ...state,
        ...action.lists,
        content: state.content
          ? state.content.concat(action.lists.content)
          : action.lists.content,
      };
    case 'WISHLISTS_DELETE_SUCCESS':
      const newArrayContent = state.content.slice();
      const index = R.findIndex(R.propEq('id', action.id))(newArrayContent);
      newArrayContent.splice(index, 1);
      return {
        ...state,
        ...action.lists,
        content: newArrayContent,
      };
    case 'WISHLISTS_SAVE_SUCCESS':
      const updateArrayContent = state.content ? state.content.slice() : [];
      updateArrayContent.splice(0, 0, action.wishlist);
      return {
        ...state,
        ...action.lists,
        content: updateArrayContent,
      };
    case 'CLEAR_WISHLISTS_LISTS':
      return { content: [] };
    case 'WISHLISTS_UPDATE_SUCCESS':
    default:
      return state;
  }
}

function wishlistTypes(state = [], action) {
  switch (action.type) {
    case 'WISHLISTS_TYPES_SUCCESS':
      return action.types;
    default:
      return state;
  }
}

function hasMore(state = false, action) {
  switch (action.type) {
    case 'WISHLISTS_LISTS_SUCCESS':
      return action.lists.content.length === action.pageSize;
    case 'WISHLISTS_FETCH_PROGRESS':
      return false;
    default:
      return state;
  }
}

function pageIndex(state = 1, action) {
  switch (action.type) {
    case 'WISHLISTS_LISTS_SUCCESS':
      return state + 1;
    case 'CLEAR_WISHLISTS_LISTS':
      return (state = 1);
    default:
      return state;
  }
}

function wishlist(state = {}, action) {
  switch (action.type) {
    case 'WISHLISTS_WISHLIST_SUCCESS':
      return action.wishlist;
    default:
      return state;
  }
}

function wishlistPublic(state = {}, action) {
  switch (action.type) {
    case 'WISHLISTS_PUBLIC_WISHLIST_SUCCESS':
      return action.wishlist;
    default:
      return state;
  }
}

function goals(state = [], action) {
  switch (action.type) {
    case 'WISHLISTS_WISHLIST_GOALS_SUCCESS':
      return action.goals;
    case 'WISHLIST_DELETE_GOAL_SUCCESS':
      const index = state.map(item => item.id).indexOf(action.id);
      if (index > 0) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      }
      return state;

    default:
      return state;
  }
}

function userGoals(state = [], action) {
  switch (action.type) {
    case 'WISHLISTS_USER_GOALS_LISTS_SUCCESS':
      return R.uniqBy(x => x.id, [...state, ...action.goals]);
    case 'WISHLIST_DELETE_GOAL_SUCCESS':
      const index = state.map(item => item.id).indexOf(action.id);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    case 'WISHLISTS_USER_GOALS_LISTS_FAIL':
      return state;
    default:
      return state;
  }
}

function userGoalsHasMore(state = false, action) {
  switch (action.type) {
    case 'WISHLISTS_USER_GOALS_LISTS_SUCCESS':
      return action.goals.length === action.pageSize;
    case 'WISHLISTS_USER_GOALS_FETCH_PROGRESS':
      return false;
    default:
      return state;
  }
}

function userGoalsPageIndex(state = 1, action) {
  switch (action.type) {
    case 'WISHLISTS_USER_GOALS_LISTS_SUCCESS':
      return state + 1;
    case 'WISHLISTS_USER_CLEAR_GOALS_LIST':
      state = 1;
      return state;
    case 'GOAL_CREATE_SUCCESS':
      return 1;
    default:
      return state;
  }
}

function newUserGoal(state = null, action) {
  switch (action.type) {
    case 'GOAL_CREATE_SUCCESS':
      return action.goal;
    case 'NEW_GOAL_RESET':
      return null;
    default:
      return state;
  }
}

function invitees(state = null, action) {
  switch (action.type) {
    case 'WISHLISTS_INVITEES_SUCCESS':
      return action.invitees;
    default:
      return state;
  }
}

export default combineReducers({
  lists,
  wishlist,
  wishlistPublic,
  goals,
  userGoals: combineReducers({
    goals: userGoals,
    hasMore: userGoalsHasMore,
    pageIndex: userGoalsPageIndex,
    newGoal: newUserGoal,
  }),
  wishlistTypes,
  hasMore,
  pageIndex,
  invitees,
});
