import { combineReducers } from 'redux';

function accepted(state = null, action) {
  switch (action.type) {
    case 'COACHMARK_ACCEPTED':
      return action.page;
    default:
      return state;
  }
}

export default combineReducers({
  accepted,
});
