import api from '../../../api';

export const PAGE_SIZE = 10;

export const isCustomEvent = type => {
  const existingEvents = [
    'baby',
    'bachelor',
    'birthday',
    'holiday',
    'farewell',
    'vacation',
    'wedding',
    'house-warming',
  ];

  return typeof existingEvents.find(e => e === type) === 'undefined';
};

export const getNewWishlist = wishlist => {
  const newWishlist = { ...wishlist };
  newWishlist.eventType =
    wishlist.eventType === 'custom' ? null : parseInt(wishlist.eventType, 10);
  return newWishlist;
};

export const getWishlists = page => {
  const index = page && page.index ? `?pageIndex=${page.index}` : '';
  const idxPrefix = index ? '&' : '?';
  const size = page && page.size ? `${idxPrefix}pageSize=${page.size}` : '';
  return api({ url: `/me/wishlists${index}${size}` });
};

export const saveWishlists = wishList => {
  return api({ url: `/me/wishlists`, method: 'post', data: wishList });
};

export const deleteWishlist = id => {
  return api({
    method: 'DELETE',
    url: `/me/wishlists/${id}`,
  });
};

export const updateWishlist = (id, data) =>
  api({ url: `/me/wishlists/${id}/`, method: 'PATCH', data });

export const getEventTypes = () => {
  return api({ url: `/me/wishlists/types` });
};

export const getWishList = id =>
  api({ url: `/me/wishlists/${id}` }).then(response => response.data);

export const getWishListGoals = id =>
  api({ url: `/me/wishlists/${id}/goals` }).then(response => response.data);

export const removeGoal = (id, goalId, goalsIds) => {
  const wishlistGoalsIds = [...goalsIds];
  const foundIdx = wishlistGoalsIds.indexOf(goalId);
  if (foundIdx > -1) {
    wishlistGoalsIds.splice(foundIdx, 1);
    return updateWishlist(id, { goals: wishlistGoalsIds });
  }
  return Promise.resolve();
};

export const getWishListInvitees = uuid =>
  api({ url: '/me/wishlists/invitees' }).then(response => {
    if (!response.data) return response;

    if (response.data && !Array.isArray(response.data)) return [];

    return response.data.filter(
      inv =>
        inv.wishlistUuids !== undefined &&
        inv.wishlistUuids.findIndex(wUuid => wUuid === uuid) !== -1
    );
  });

export const saveWishListInvitees = wishListInvitees =>
  api({
    url: '/me/wishlists/invitees',
    method: 'POST',
    data: wishListInvitees,
  });

export const getWishlistByType = typeId =>
  api({ url: `/me/wishlists/types/${typeId}` });

export const acceptWishlistInvitation = invitationUuid =>
  api({
    url: `/me/wishlists/invitations/${invitationUuid}/accept`,
    method: 'POST',
  });

export const getPublicWishlist = uuid => api({ url: `/wishlists/${uuid}` });
