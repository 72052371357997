const freshDeskInit = (email, region) => {
  const FreshWidget = window.FreshWidget;
  const receiver = encodeURIComponent(email || '');
  FreshWidget.init('', {
    queryString: `&widgetType=popup&formTitle=Support+%26+Feedback&submitTitle=Send+Feedback&submitThanks=Yay!%20We're%20so%20excited%20to%20hear%20from%20you!%20Sit%20tight,%20someone%20from%20Doni%20will%20contact%20you%20shortly.&captcha=yes&helpdesk_ticket[custom_field][environment_435924]=${region}&helpdesk_ticket[requester]=${receiver}`,
    utf8: '✓',
    widgetType: 'popup',
    buttonType: 'text',
    buttonText: 'Feedback',
    buttonColor: 'white',
    buttonBg: '#37A995',
    alignment: '4',
    offset: '240px',
    formHeight: '650px',
    captcha: 'yes',
    url: 'https://doni.freshdesk.com',
  });
};

export default freshDeskInit;
