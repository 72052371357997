// @flow

import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { withCookies } from 'react-cookie';

import { getUserRegion } from '../../services/location';
import * as constants from '../../services/constants';
import './styles.css';

const userRedirectWebBase = constants.USER_REDIRECT_WEB_BASE;

type Props = {
  t: string => string,
  cookies: {
    set: (string, string) => void,
    get: string => string,
  },
};

type State = {
  showRegionWarning: boolean,
};

export class RegionWarning extends React.Component<Props, State> {
  state = {
    showRegionWarning: false,
  };
  regionWarningDismiss = () => {
    this.props.cookies.set('region_warning_dismissed', 'true');
    this.setState({
      showRegionWarning: false,
    });
  };
  componentDidMount() {
    !this.props.cookies.get('region_warning_dismissed') &&
      getUserRegion(0).then(data => {
        if (data) {
          this.setState({
            showRegionWarning: true,
          });
        } else {
          //user region ok, no need to check agin soon
          this.props.cookies.set('region_warning_dismissed', 'true');
        }
      });
  }
  render() {
    const { t } = this.props;
    return (
      <Fragment>
        {this.state.showRegionWarning && (
          <div className="region-warning-banner">
            <div className="region-warning-banner__content">
              <div>
                {t('global.region-warning-1')}
                <a href={userRedirectWebBase}>{userRedirectWebBase}</a>
                {t('global.region-warning-2')}
              </div>
              <div>
                <a href={userRedirectWebBase}>{t('global.take-me')}</a>
                <span
                  className="region-warning-banner__stay-here"
                  onClick={() => this.regionWarningDismiss()}
                >
                  {t('global.stay-here')}
                </span>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withCookies(translate()(RegionWarning));
