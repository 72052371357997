import React, { Fragment } from 'react';
import Loadable from 'react-loadable';

import PrivateLayout from '../../../layout/Private';
import PublicRoute from '../../../components/PublicRoute';
import PublicLayout from '../../../layout/Mixed';
import Loading from '../../../components/Loading';

// const Wishlist = Loadable({
//   loader: () => import('../components/Wishlist'),
//   loading: Loading,
// });
const CreateEvent = Loadable({
  loader: () => import('../components/CreateEvent'),
  loading: Loading,
});

const ListiRoutes = (
  <Fragment>
    {/* <ListyRoute
      path="/listy/wishlist"
      exact
      layout={ListyLayout}
      component={Wishlist}
    /> */}
    <PublicRoute
      path="/listy/event-create"
      exact
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={CreateEvent}
    />
    <PublicRoute
      path="/listi/event-create"
      exact
      publicLayout={PublicLayout}
      privateLayout={PrivateLayout}
      component={CreateEvent}
    />
  </Fragment>
);

export default ListiRoutes;
