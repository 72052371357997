import React, { Fragment } from 'react';
import Loadable from 'react-loadable';
import AuthLayout from '../../../layout/Auth';
import RouteWrapper from '../../../components/RouteWrapper';
import Loading from '../../../components/Loading';

const SignUp = Loadable({
  loader: () => import('../components/SignUp'),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import('../components/Login'),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import('../components/Logout'),
  loading: Loading,
});

const ResetPassword = Loadable({
  loader: () => import('../components/ResetPassword'),
  loading: Loading,
});

const EmailVerification = Loadable({
  loader: () => import('../components/SignUp/EmailVerification'),
  loading: Loading,
});

const EmailVerificationConfirmation = Loadable({
  loader: () => import('../components/SignUp/EmailVerificationConfirmation'),
  loading: Loading,
});

const AuthRoutes = (
  <Fragment>
    <RouteWrapper
      path="/sign-up"
      exact
      layout={AuthLayout}
      component={SignUp}
    />
    <RouteWrapper
      path="/register"
      exact
      layout={AuthLayout}
      component={SignUp}
    />
    <RouteWrapper
      path="/verification/confirmation"
      layout={AuthLayout}
      component={EmailVerificationConfirmation}
    />
    <RouteWrapper
      path="/verification"
      layout={AuthLayout}
      component={EmailVerification}
    />
    <RouteWrapper path="/login" layout={AuthLayout} component={Login} />
    <RouteWrapper path="/logout/:redirect?" layout={AuthLayout} component={Logout} />
    <RouteWrapper
      path="/reset-password"
      layout={AuthLayout}
      component={ResetPassword}
    />
  </Fragment>
);

export default AuthRoutes;
