import browserDetect from 'browser-detect';
import { REGION } from '../../../services/constants';

export const extensionLink = () => {
  const browser = browserDetect();
  const extensionLinks = {
    chrome: {
      EU:
        'https://chrome.google.com/webstore/detail/doni-goals-extension/oelocmelkfdiaakjlkfokeibdihikmjj',
      // US: 'https://chrome.google.com/webstore/detail/doni-goals-extension/usid',
    },
    // opera: {
    //   EU: 'opera eu link',
    //   US: 'opera us link',
    // },
    // edge: {
    //   EU: 'edge eu link',
    //   US: 'edge us link',
    // },
    // safari: {
    //   EU: 'safari eu link',
    //   US: 'safari us link',
    // },
    firefox: {
      EU: 'https://addons.mozilla.org/en-GB/firefox/addon/doni-goals/',
      // US: 'firefox us link',
    },
  };
  console.log(browser.name, REGION)
  return extensionLinks[browser.name]
    ? extensionLinks[browser.name][REGION]
      ? extensionLinks[browser.name][REGION]
      : undefined
    : undefined;
};
