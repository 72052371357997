const setCookie = (name, value, minutes) => {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = `expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value};path=/;${expires};`;
};

const getCookie = name => {
  const cookies = document.cookie.match(
    '(^|;)\\s*' + name + '\\s*=\\s*([^;]+)'
  );
  return cookies ? cookies.pop() : '';
};

const removeCookie = name => {
  document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

const cookieUtils = {
  setCookie: setCookie,
  removeCookie: removeCookie,
  getCookie: getCookie,
};

export default cookieUtils;
