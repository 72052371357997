// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';

import i18n from '../../i18n';
import Button from '../../components/Button';
import { languageChanged } from '../../actions/languageChanged';

type Props = {
  language?: string,
  languageChanged: () => void,
};
type State = {
  language?: string,
};

export class LanguageSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      language: props.language,
    };
  }

  changeLanguage = (language: string): void => {
    this.setState({ language });
    i18n.changeLanguage(language, () => {
      this.props.languageChanged();
    });
  };
  componentDidUpdate = (prevProps: Props) => {
    if (this.props.language !== prevProps.language) {
      this.setState({
        language: this.props.language,
      });
    }
  };

  render() {
    return (
      <div className="header__language-selector">
        <Button
          testSelector="header_change-language-en"
          className={`button--half-left button ${
            this.state.language === 'en-GB'
              ? 'button--active'
              : 'button--inactive'
          }`}
          onClick={() => this.changeLanguage('en-GB')}
        >
          EN
        </Button>
        <Button
          testSelector="header_change-language-nl"
          className={`button--half-right button ${
            this.state.language === 'nl-NL'
              ? 'button--active'
              : 'button--inactive'
          }`}
          onClick={() => this.changeLanguage('nl-NL')}
        >
          NL
        </Button>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  languageChanged: () => dispatch(languageChanged()),
});
export default connect(
  null,
  mapDispatchToProps
)(LanguageSelector);
