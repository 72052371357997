// @flow

import * as React from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import MobileNavigation from '../MobileNavigation';
import Footer from '../../components/Footer';
import NotifToastr from '../../modules/Notif/components/NotifToastr';
import Loader from '../../components/Loader';
import CookieCheck from '../../modules/Notif/components/CookieCheck';
import ErrorBoundary from '../../components/ErrorBoundary';
import mobileUtils from '../../startupUtils/mobile';

import './styles.css';

type Props = {
  children?: React.Node | string,
};

class PrivateLayout extends React.Component<Props> {
  lavaMenu = () => {
    const lava = document.getElementById('lava');
    const activeItem = document.querySelector(
      '.header__navigation-list-item--active'
    );
    if (lava && activeItem) {
      const getLeft = activeItem.offsetLeft;
      const getWidth = activeItem.offsetWidth;
      lava.style.left = `${getLeft}px`;
      lava.style.width = `${getWidth}px`;
    }
  };
  componentDidUpdate() {
    this.lavaMenu();
  }
  render() {
    return (
      <React.Fragment>
        <Loader />
        <header className="private header">
          <NotifToastr />
          <CookieCheck />
          <div
            className={`${
              mobileUtils.isOnMobile() ? 'header__container--mobile' : ''
            } header__container`}
          >
            <Link to="/events" ta-id="header_logo">
              <div className="header__logo" />
            </Link>
            {mobileUtils.isOnMobile() ? (
              <MobileNavigation isPrivate={true} />
            ) : (
              <Navigation />
            )}
          </div>
        </header>
        <main className="content content--private">
          <ErrorBoundary>{this.props.children}</ErrorBoundary>
        </main>
        <Footer className="private" />
      </React.Fragment>
    );
  }
}

export default translate()(PrivateLayout);
