import { all, call, put, takeEvery } from 'redux-saga/effects';
import { archiveNotification } from '../../Notifications/services';

function* linkNotif(action) {
  try {
    const { notification } = action;

    if (notification === undefined) return;
    const { payload } = notification;
    yield put({ type: 'NOTIFICATION_ARCHIVE', notification });
    switch (notification.notificationType) {
      case 'comment':
        yield put({
          type: 'NOTIF_EVENT_COMMENT',
          eventId: payload.eventId,
        });
        break;
      case 'contribution':
        yield put({
          type: 'NOTIF_EVENT_CONTRIBUTION',
          eventId: payload.eventId,
        });
        break;
      case 'event_sharing':
      case 'event_invitation':
        yield put({
          type: 'NOTIF_EVENT_SHARING',
          eventId: payload.eventId,
          eventUuid: payload.eventUuid,
          invitationUuid: payload.invitationUuid,
        });
        break;
      case 'goal_amount_reached':
        yield put({
          type: 'NOTIF_EVENT_AMOUNT_REACHED',
          eventId: payload.eventId,
        });
        break;
      case 'spend_money_via_email':
        yield put({
          type: 'NOTIF_EVENT_CASH_OUT',
          eventId: payload.eventId,
          eventUuid: payload.eventUuid,
          invitationUuid: payload.invitationUuid,
        });
        break;
      case 'time_left_to_contribute':
      case 'time_left_three_days':
        yield put({
          type: 'NOTIF_EVENT_TIME_LEFT_THREE_DAYS',
          eventId: payload.eventId,
        });
        break;
      case 'time_left_to_cash_out':
        yield put({
          type: 'NOTIF_EVENT_TIME_LEFT_TO_CASH_OUT',
          eventId: payload.eventId,
        });
        break;
      case 'vote':
        yield put({
          type: 'NOTIF_EVENT_VOTE',
          eventId: payload.eventId,
        });
        break;
      case 'wishlist_created':
        yield put({
          type: 'NOTIF_EVENT_WISHLIST_CREATED',
          eventId: payload.eventId,
        });
        break;
      case 'wishlist_request_no_goals':
      case 'wishlist_request_acknowledge':
      case 'wishlist_request_account_goals':
        yield put({
          type: 'NOTIF_EVENT_WISHLIST_REQUEST_NO_GOALS',
          eventUuid: payload.eventUuid,
          requestUuid: payload.requestUuid,
        });
        break;
      case 'wishlist_sharing':
        yield put({
          type: 'NOTIF_WISHLIST_SHARING',
          wishlistUuid: payload.wishlistId, // payload.wishlistId is actually wishlistUuid
        });
        break;
      case 'bank_transfer_success':
      case 'bank_transfer_failure':
      case 'time_left_0_days_to_cashout':
        break;
      default:
        break;
    }
  } catch (error) {
    yield put({ type: 'NOTIFICATION_ACTION_FAILED' });
  }
}

function* archiveNotif(action) {
  yield call(archiveNotification, action.notification);
  yield put({
    type: 'NOTIFICATION_ARCHIVE_SUCCESS',
    id: action.notification.id,
  });
}

export function* watchNotifications() {
  yield all([
    takeEvery('NOTIFICATION_ARCHIVE', archiveNotif),
    takeEvery('NOTIFICATION_ACTION_REQUESTED', linkNotif),
  ]);
}
