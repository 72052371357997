import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import * as constants from './services/constants';

const region = constants.REGION;
const i18nextLanguageDetectorOptions = {
  order: ['cookie', 'localStorage'],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    detection: i18nextLanguageDetectorOptions,
    // lng: region === 'EU' ? 'en-GB' : 'en-US',
    fallbackLng: region === 'EU' ? 'nl-NL' : 'en-US',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },
  });

export default i18n;
