import React, { Component } from 'react';
import ErrorState from './ErrorState';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';

class ErrorBoundary extends Component {
  state = { hasError: undefined };

  componentDidCatch(error, info) {
    // Display fallback UI
    const { key } = this.props.location;
    this.setState({ hasError: { [key]: true } });

    Sentry.configureScope(scope => {
      Object.keys(info).forEach(k => {
        scope.setExtra(k, info[k]);
      });
    });
    Sentry.captureException(error);
  }

  submitFeedback = (feedBackWidget: Array, t, e) => {
    e.preventDefault();
    const obj = {};
    for (let i = 0; i < feedBackWidget.length; i++) {
      const key = feedBackWidget[i].split('.').pop();
      obj[key] = t(feedBackWidget[i]);
    }
    obj.subtitle = '';
    obj.eventId = this.props.sentryEventId;
    Sentry.showReportDialog(obj);
  };

  hasError = () => {
    const { key } = this.props.location;
    return this.state.hasError && this.state.hasError[key];
  };

  render() {
    if (this.hasError()) {
      return <ErrorState submitFeedback={this.submitFeedback} />;
    }
    return this.props.children;
  }
}

const makeMapStateToProps = dispatch => {
  const mapStateToProps = (state, props) => {
    return {
      sentryEventId: state.sentry,
    };
  };
  return mapStateToProps;
};

export default withRouter(
  connect(
    makeMapStateToProps,
    null
  )(ErrorBoundary)
);
