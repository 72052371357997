import { apiExternal } from '../api';
import { IPDATA_API_KEY, REGION } from './constants';

const getUserLocation = url =>
  apiExternal({
    method: 'get',
    url: url,
  });

export const getUserRegion = (index: number = 0) => {
  const freeServices = IPDATA_API_KEY
    ? [`https://api.ipdata.co?api-key=${IPDATA_API_KEY}`]
    : [];
  const availableIn = {
    EU: [
      'BE',
      'BG',
      'CZ',
      'DK',
      'DE',
      'EE',
      'IE',
      'EL',
      'ES',
      'FR',
      'HR',
      'IT',
      'CY',
      'LV',
      'LT',
      'LU',
      'HU',
      'MT',
      'NL',
      'AT',
      'PL',
      'PT',
      'RO',
      'SI',
      'SK',
      'FI',
      'SE',
      'NO',
      'UK',
      'GB',
      'GR',
      'CH',
      'MD',
      'IS',
      'FO',
      'AX',
    ],
    US: ['US', 'CA', 'MX'],
  };
  return new Promise((resolve, reject) => {
    if (index <= freeServices.length) {
      getUserLocation(freeServices[index])
        .then(data => {
          if (data && data.country_code) {
            resolve(!(availableIn[REGION].indexOf(data.country_code) >= 0));
          }
        })
        .catch(e => {
          const next = index + 1;
          resolve(getUserRegion(next));
        });
    } else {
      reject('no services available');
    }
  });
};
