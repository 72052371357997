// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as notificationsActions from '../../actions';
import type { Notification } from '../../types';
import makeNotificationsState from '../../selectors/notificationsNavSelectors';
import NotificationNavItem from '../NotificationsNavItem';
import EmptyNotifications from '../../../../components/EmptyNotifications';
import './styles.css';

type Props = {
  t: string => string,
  removeNotifications: (id: number) => void,
  markNotificationAsRead: (id: number) => void,
  history: string => void,
  notifications: Array<Notification>,
  loadNotifications: () => Array<Notification>,
  handleLinkClick: () => void,
};

class NotificationsNavList extends Component<Props> {
  componentDidMount() {
    this.props.loadNotifications();
  }

  render() {
    const { t, notifications } = this.props;
    return (
      <div className="notifications-nav">
        <div className="notifications-nav__title">
          {t('notifications.title')}
        </div>
        <ul>
          {notifications &&
            notifications.length === 0 && <EmptyNotifications />}
          {notifications &&
            notifications.map(notification => (
              <NotificationNavItem
                key={`notifications-nav-${notification.id}`}
                notification={notification}
                handleLinkClick={this.props.handleLinkClick}
              />
            ))}
        </ul>
        <div className="notifications-nav__footer">
          <Link
            ta-id="header_notifications-view-all"
            className={`notifications-nav__footer--link ${
              notifications && notifications.length === 0 ? 'disabled' : ''
            }`}
            to={`/notifications`}
            onClick={this.props.handleLinkClick}
          >
            {t('notifications.view-all')}
          </Link>
        </div>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const userNotifications = makeNotificationsState();
  const mapStateToProps = (state, props) => {
    return {
      notifications: userNotifications(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  loadNotifications: () => dispatch(notificationsActions.fetchNotifications()),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(translate()(NotificationsNavList));
