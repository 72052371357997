// @flow

import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

type Props = {
  component: React.ComponentType<*>,
  layout: React.ComponentType<*>,
  location: {
    search: string,
    pathname: string,
  },
};

// Temporary redirects needed until links from
// emails and cognito are updated according to our url schema
const redirectTo = (query, pathName) => {
  const params = new URLSearchParams(query);
  if (params.get('eventUuid') && pathName === '/event/details') {
    const eventUuid = params.get('eventUuid') || '';
    return `/events/${eventUuid}/details`;
  }
  if (params.get('wishlistUuid') && pathName === '/u/wishlist') {
    const wishlistUuid = params.get('wishlistUuid') || '';
    return `/wishlist-public/${wishlistUuid}/`;
  }
  if (params.get('eventUuid') && params.get('invitationUuid')) {
    const eventUuid = params.get('eventUuid') || '';
    const invitationUuid = params.get('invitationUuid') || '';
    if (pathName === '/event/spend/bank') {
      return `/events/${eventUuid}/invitations/${invitationUuid}/spend`;
    } else {
      const invitationUuid = params.get('invitationUuid') || '';
      return `/events/${eventUuid}/${invitationUuid.split('/')[0]}/join`;
    }
  }
  if (params.get('eventId')) {
    const eventId = params.get('eventId') || '';
    switch (pathName) {
      case '/event/discuss':
        return `/events/${eventId}#tab0`;

      case '/event/contributors':
        return `/events/${eventId}#tab1`;

      default:
        return `/events/${eventId}/wishlist`;
    }
  }
  if (params.get('eventUuid')) {
    const eventUuid = params.get('eventUuid') || '';
    switch (pathName) {
      case '/event/discuss':
        return `/events/${eventUuid}#tab0`;
      case '/event/join':
        if (params.get('organizationId')) {
          const organizationId = params.get('organizationId');
          //join event from listi or partner with organizationId
          return `/events/${eventUuid}/join/organizationId/${organizationId}`;
        }
        return `/events/${eventUuid}/join`;
      case '/event/contribute':
        const invitationUuid = params.get('invitationUuid') || '';
        // redirect UDEN user directly to contribute
        const amountToContribute = params.get('amountToContribute');
        amountToContribute &&
          window.localStorage.setItem(
            'amountToContribute',
            JSON.stringify(amountToContribute)
          );
          const ticketType = params.get('ticketType');
          ticketType &&
            window.localStorage.setItem(
              'ticketType',
              JSON.stringify(ticketType)
            );
        return `/events/${eventUuid}/contribute/${invitationUuid}`;
      case '/event/contributors':
        return `/events/${eventUuid}#tab1`;

      default:
        return `/events/${eventUuid}/wishlist`;
    }
  }
  if (params.get('fbclid')) {
    //facebook ads click id
    return '/';
  }
  if (pathName === '/register') {
    return '/sign-up';
  }
  return '/not-found';
};

const RouteWrapper = ({
  component: Component,
  layout: Layout,
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={(props: Props) => (
      <Layout>
        {props.location.search.length > 0 &&
        props.location.pathname !== '/payment-return' &&
        props.location.pathname !== '/login' &&
        props.location.pathname !== '/reset-password' &&
        props.location.pathname !== '/verification/confirmation' &&
        props.location.pathname !== '/listi/event-create' &&
        props.location.pathname.split('/')[1] !== 'requested-wishlist' ? (
          <>
            <Redirect
              to={redirectTo(props.location.search, props.location.pathname)}
            />
          </>
        ) : (
          <Component {...props} />
        )}
      </Layout>
    )}
  />
);

export default RouteWrapper;
