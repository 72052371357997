// @flow

import * as React from 'react';

import './styles.css';

type Props = {
  type?: string,
  className?: string,
  onClick?: (ev: SyntheticEvent<HTMLButtonElement>) => void,
  children?: React.Node | string,
  disabled?: boolean,
  testSelector?: string,
};

const Button = (props: Props) => (
  <button
    onClick={props.onClick}
    disabled={props.disabled}
    className={props.className}
    type={props.type}
    ta-id={props.testSelector}
  >
    {props.children}
  </button>
);

Button.defaultProps = {
  type: undefined,
  className: 'button',
  onClick: undefined,
  disabled: false,
};

export default Button;
