import { createSelector } from 'reselect';

const getAccepted = state => state.coachmark.accepted;

const getUserUuid = state => state.auth.user;

const getCoachmark = state => state.toastr.toastrs;

const makeAcceptedState = () =>
  createSelector([getAccepted], accepted => accepted);

export const makeUserUuidState = () =>
  createSelector([getUserUuid], user => btoa(user.sub));

export const makeCoachmarkToastrState = () =>
  createSelector([getCoachmark], toastrs => {
    if (toastrs && toastrs.length > 0) {
      const coachmarkToastr = toastrs.find(
        elem => elem.title === 'toastr coachmark'
      );
      return coachmarkToastr ? coachmarkToastr.id : null;
    }
    return null;
  });

export default makeAcceptedState;
