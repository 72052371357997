import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { Component } from 'react';
import { ENVIRONMENT } from '../services/constants';
import { sentryEventId } from '../actions/sentry';

class SentryInit extends Component {
  constructor(props) {
    super(props);
    if (ENVIRONMENT !== 'LOCAL') {
      Sentry.init({
        dsn: 'https://e74178e411a84ab1a84f9a33b4855815@sentry.io/1256666',
        environment: `${ENVIRONMENT}_${this.props.region}`,
        beforeSend: event => {
          this.props.sentryEventId(event.event_id);
          return event;
        },
      });
    } else {
      return false;
    }
  }

  render() {
    return false;
  }
}

const mapDispatchToProps = dispatch => ({
  sentryEventId: eventId => dispatch(sentryEventId(eventId)),
});

export default connect(
  null,
  mapDispatchToProps
)(SentryInit);
