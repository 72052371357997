// @flow

import * as React from 'react';
import { toastr } from 'react-redux-toastr';

const toastrOptions: {
  icon: React.Node,
  component: React.Node,
} = {
  icon: <span />,
  component: <React.Fragment />,
};

const notif = {};

notif.info = (CustomComponent: React.ComponentType<any>) => {
  toastrOptions.component = <CustomComponent />;
  return toastr.info('', '', toastrOptions);
};

notif.success = (CustomComponent: React.ComponentType<any>) => {
  toastrOptions.component = <CustomComponent />;
  return toastr.success('', '', toastrOptions);
};

notif.warning = (CustomComponent: React.ComponentType<any>) => {
  toastrOptions.component = <CustomComponent />;
  return toastr.warning('', '', toastrOptions);
};
notif.warningSticky = (CustomComponent: React.ComponentType<any>) => {
  toastrOptions.component = <CustomComponent />;
  toastrOptions.timeOut = 0;
  toastrOptions.removeOnHover = false;
  return toastr.warning('', '', toastrOptions);
};

notif.error = (CustomComponent: React.ComponentType<any>) => {
  toastrOptions.component = <CustomComponent />;
  return toastr.error('', '', toastrOptions);
};

export default notif;
