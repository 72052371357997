// @flow

import api from '../../../api';
import { REGION } from '../../../services/constants';

const checkUrl = url => (url.substr(0, 4) !== 'http' ? `https://${url}` : url);

export const setEngagementForm = REGION === 'EU' ? ['en', 'nl'] : ['en'];

export const hasEngagementPermissions = roles => {
  roles = roles.map(role => role.name);
  return roles.includes('ROLE_ADMIN') || roles.includes('ROLE_MARKETING');
};

export const uploadImage = async image => {
  const data = new FormData();
  data.append('file', image);

  return api({
    method: 'post',
    url: `me/images`,
    data: data,
  });
};

export const sendEngagementNotification = engagementNotificationData => {
  return api({
    method: 'post',
    url: 'me/users/notifications',
    data: engagementNotificationData,
  });
};

export const resetFileInput = () => {
  const fileInput = document.querySelector('input[type="file"]');
  if (fileInput) fileInput.value = '';
};

export const sendEngagementData = data => {
  let engagementData = {};
  const languages = setEngagementForm.map(lang => {
    return {
      language: lang,
      title: data[`title_${lang}`],
      body: data[`body_${lang}`],
    };
  });
  engagementData = {
    action: data.action.value,
    languages: languages,
  };

  return new Promise((resolve, reject) => {
    if (data.imageUrl) {
      uploadImage(data.imageUrl[0])
        .then(response => {
          engagementData.attachmentUrl = checkUrl(response.data.attachmentUrl);
          sendEngagementNotification(engagementData)
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    } else {
      sendEngagementNotification(engagementData)
        .then(resolve)
        .catch(reject);
    }
  });
};
