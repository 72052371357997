import { combineReducers } from 'redux';
import * as R from 'ramda';
import products from './products';
import offers from './offers';

function goals(state = { goals: null }, action) {
  switch (action.type) {
    case 'GOALS_LISTS_SUCCESS':
      return {
        ...state,
        ...action.goals,
        goals: R.uniqBy(
          x => x.id,
          state.goals
            ? [...state.goals, ...action.goals.goals]
            : action.goals.goals
        ),
      };
    case 'GOAL_DELETE_SUCCESS':
      if (!state.goals) {
        return { goals: null };
      }

      const index = state.goals.map(item => item.id).indexOf(action.id);
      return {
        ...state,
        goals: [
          ...state.goals.slice(0, index),
          ...state.goals.slice(index + 1),
        ],
      };
    case 'CLEAR_GOALS_LIST':
      return { goals: null };
    case 'GOALS_LISTS_FAIL':
      return state;
    case 'GOAL_CREATE_FAIL':
      return state;
    default:
      return state;
  }
}

function newGoal(state = {}, action) {
  switch (action.type) {
    case 'GOAL_CREATE_SUCCESS':
      return action.goal;
    default:
      return state;
  }
}

function hasMore(state = false, action) {
  switch (action.type) {
    case 'GOALS_LISTS_SUCCESS':
      return action.goals.goals.length === action.pageSize;
    case 'GOALS_FETCH_PROGRESS':
      return false;
    default:
      return state;
  }
}

function pageIndex(state = 1, action) {
  switch (action.type) {
    case 'GOALS_LISTS_SUCCESS':
      return state + 1;
    case 'GOAL_DELETE_SUCCESS':
      return state - 1;
    case 'CLEAR_GOALS_LIST':
      state = 1;
      return state;
    default:
      return state;
  }
}

function goal(state = null, action) {
  switch (action.type) {
    case 'GOAL_FETCH_SUCCESS':
    case 'GOAL_EDIT_SUCCESS':
      return action.goal;
    case 'CLEAR_GOAL':
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  goal,
  goals,
  hasMore,
  pageIndex,
  newGoal,
  products,
  offers,
});
