// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Loading from '../Loading';

type Props = {
  loading: boolean,
};

const Loader = ({ loading }: Props) => (
  <Fragment>{loading && <Loading />}</Fragment>
);

const makeMapStateToProps = (state, props) => ({
  loading: state.calls > 0,
});

export default connect(makeMapStateToProps)(Loader);
