import api from '../../../api';

export const PAGE_SIZE = 10;

export const getNotifications = (page = { index: 1, size: PAGE_SIZE }) =>
  api({
    url: `/me/notifications?pageIndex=${page.index}&pageSize=${page.size}`,
  }).then(response => response.data);

export const getNotificationsCount = () =>
  api({ url: '/me/notifications/counter' }).then(response => response.data);

export const deleteNotification = id =>
  api({ method: 'DELETE', url: `/me/notifications/${id}` });

export const archiveNotification = notification => {
  api({
    method: 'PATCH',
    url: `/me/notifications/${notification.id}`,
    data: {
      ...notification,
      archive: false,
    },
  });
};
