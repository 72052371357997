import { createSelector } from 'reselect';

const getNotifications = state => state.notifications.notifications;

const makeNotificationsState = () =>
  createSelector(
    [getNotifications],
    notifications =>
      notifications &&
      notifications
        .slice(0, notifications.length > 4 ? 4 : notifications.length)
        .map(notification => ({
          id: notification.id,
          createdDate: notification.createdDate,
          archive: notification.archive,
          notificationType: notification.notificationType,
          payload: {
            eventId: notification.payload.eventId,
            eventUuid: notification.payload.eventUuid,
            eventType: notification.payload.eventType,
            body: notification.payload.body,
            wishlistId: notification.payload.wishlistId,
            requestUuid: notification.payload.requestUuid,
            invitationUuid: notification.payload.invitationUuid,
          },
        }))
  );

export default makeNotificationsState;
