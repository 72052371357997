// @flow

import React from 'react';

import './styles.css';

const Loading = () => (
  <div className="loader__overlay">
    <ul className="loader__animation">
      <li />
      <li />
      <li />
      <li />
    </ul>
  </div>
);

export default Loading;
