// @flow
import * as constants from './constants';
import notif from '../modules/Notif/services';
import i18n from '../i18n';
import * as R from 'ramda';
import type { Contributor as ContributorType } from '../modules/Events/types';

type FileType = {
  type: string,
  size: number,
};

export const getFormattedDate = (date: Date, locale: string): string => {
  if (date === undefined || date === null) return '';

  const d = new Date(date);
  return d.toLocaleDateString(locale, dateFormats[locale]);
};

const dateFormats: {
  [locale: string]: Intl$DateTimeFormatOptions,
} = {
  'en-US': {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  'en-GB': {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  'nl-NL': {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
};

export const getStartOfDay = (date: Date): Date => {
  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  return date;
};

type ValueFormat = {
  thousandSeparator: string,
  decimalSeparator: string,
  decimalScaleOff: number,
  decimalScaleOn: number,
  fixedDecimalScale: boolean,
};

export const getFormattedValue = (locale: string): ValueFormat => {
  return valueFormats[locale];
};

const valueFormats: {
  [locale: string]: ValueFormat,
} = {
  'en-US': {
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScaleOff: 0,
    decimalScaleOn: 2,
    fixedDecimalScale: true,
  },
  'en-GB': {
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScaleOff: 0,
    decimalScaleOn: 2,
    fixedDecimalScale: true,
  },
  'nl-NL': {
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScaleOff: 0,
    decimalScaleOn: 2,
    fixedDecimalScale: true,
  },
};

export const validateImage = (file: FileType) => {
  if (file !== undefined) {
    if (file.type.indexOf('image') < 0) {
      notif.warning(i18n.t('components.upload-photo.wrong-file'), '');
      return false;
    } else if (file.size > constants.MAX_IMAGE_UPLOAD_SIZE) {
      notif.warning(i18n.t('components.upload-photo.wrong-size'), '');
      return false;
    }
  } else {
    return false;
  }
  return true;
};

export const countUniqContributors = (contributors: Array<ContributorType>) => {
  const privateCount =
    contributors.filter(item => {
      return item.visibility === 'private';
    }).length || 0;
  const publicCount =
    R.uniqBy(
      x => x.email,
      contributors.filter(item => {
        return item.visibility === 'public';
      })
    ).length || 0;
  return privateCount + publicCount;
};

const browserThresholds = [
  {
    vendor: 'Safari',
    version: 9,
  },
  {
    vendor: 'Opera',
    version: 37,
  },
  {
    vendor: 'IE',
    version: 11,
  },
  {
    vendor: 'Edge',
    version: 16,
  },
  {
    vendor: 'Firefox',
    version: 46,
  },
  {
    vendor: 'Chrome',
    version: 51,
  },
];

export const isBrowserOutdated = () => {
  const ua = navigator.userAgent;
  let version,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if (/trident/i.test(M[0])) {
    version = /\brv[ :]+(\d+)/g.exec(ua) || [];
    M = ('IE ' + (version[1] || '')).split(' ');
  }
  if (M[0] === 'Chrome') {
    version = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (version != null)
      M = version
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera')
        .split(' ');
  }
  if ((version = ua.match(/version\/(\d+)/i)) != null)
    M.splice(1, 1, version[1]);
  return browserThresholds.find(function(x) {
    return x.vendor === M[0] && x.version >= parseInt(M[1], 10);
  }) === undefined
    ? undefined
    : M.join(' ');
};

export const getRouterKey = (computedMatch, location) => {
  let routerKey = '';
  if (
    computedMatch &&
    computedMatch.params &&
    !R.isEmpty(computedMatch.params)
  ) {
    routerKey =
      computedMatch.params[Object.keys(computedMatch.params)[0].toString()];
  }
  if (location && location.search !== '') {
    routerKey = location.search;
  }
  return routerKey;
};
export const isBrowserUnsupported = () => {
  const browser = isBrowserOutdated(),
    unsupported = ['IE 11', 'Safari 9'];
  return unsupported.includes(browser);
};

export const uploadExternalImage = imageUrl => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.addEventListener('load', () => {
      const file = getFileFromImage(image);
      resolve(file);
    });
    image.src = imageUrl;
  });
};

export const getFileFromImage = image => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  canvas.getContext('2d').drawImage(image, 0, 0);

  const dataUrl = canvas.toDataURL();
  let byteString = '';
  if (dataUrl.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataUrl.split(',')[1]);
  else byteString = unescape(dataUrl.split(',')[1]);

  const mimeString = dataUrl
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const file = new File([ia], 'goalImage.png', { type: mimeString });
  return file;
};
