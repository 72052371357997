import { combineReducers } from 'redux';

export function offers(state = [], action) {
  switch (action.type) {
    case 'OFFERS_FETCH_SUCCESS':
      return state.concat(action.response.products);
    case 'OFFERS_RESET':
      return [];
    default:
      return state;
  }
}

export function offersNumber(state = null, action) {
  switch (action.type) {
    case 'OFFERS_FETCH_SUCCESS':
      return action.response.offers;
    case 'OFFERS_RESET':
      return null;
    default:
      return state;
  }
}

export function loadingMore(state = true, action) {
  switch (action.type) {
    case 'OFFERS_FETCH_SUCCESS':
      return false;
    case 'OFFERS_RESET':
      return true;
    default:
      return state;
  }
}

function moreOffers(
  state = {
    fetchedOffers: 0,
    hasMore: false,
  },
  action
) {
  switch (action.type) {
    case 'OFFERS_FETCH_SUCCESS':
      return {
        ...state,
        fetchedOffers: state.fetchedOffers + action.pageLimit,
        hasMore:
          state.fetchedOffers + action.pageLimit < action.response.offers,
      };
    case 'OFFERS_RESET':
      return {
        ...state,
        fetchedOffers: 0,
        hasMore: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  offers,
  offersNumber,
  moreOffers,
  loadingMore,
});
